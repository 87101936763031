import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useToasts } from 'react-toast-notifications';
import { authenticationService } from '../../../_services'
// ----------------------------------------------------------------------

export default function LoginForm({ enableResetPage }) {
  const navigate = useNavigate();
  const { addToast } = useToasts()
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    phone: Yup.string().required('Teléfono es obligatorio'),
    password: Yup.string().required('Contraseña es obligatoria')
  });

  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      const user = await authenticationService.login(formik.values.phone, formik.values.password)
      if (user.success) {
        const urlCallback = localStorage.getItem("url_callback");
        if (urlCallback) {
          localStorage.removeItem("url_callback");
          navigate(urlCallback);
        }
        else {
          navigate("/dashboard");
        }
      }
      else {
        addToast(user.errorMessage, {
          appearance: 'info',
          autoDismiss: true,
        });
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="phone"
            label="Teléfono"
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Contraseña"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Button onClick={() => enableResetPage(true)}>
            ¿Olvidó su contraseña?
          </Button>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Iniciar sesión
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
