import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
import { ToastProvider } from 'react-toast-notifications';
import { useState } from 'react';

// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';
import ResetPasswordPage from './Account/ResetPasswordPage'
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const [resetPassword, setResetPassword] = useState(false);

  return (
    <ToastProvider>
      <RootStyle title="Login | Tu Pasaporte">
        <AuthLayout>
          No tiene una cuenta? &nbsp;
          <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
            Registrarse
          </Link>
        </AuthLayout>

        <MHidden width="mdDown">
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hola, bienvenido de nuevo
            </Typography>
            <img src="/static/illustrations/illustration_login.png" alt="login" />
          </SectionStyle>
        </MHidden>

        <Container maxWidth="sm">
          <ContentStyle>


            {
              resetPassword ?
                <div>
                  <Stack sx={{ mb: 5 }}>
                    <Typography variant="h4" gutterBottom>
                      Restablecer contraseña
                    </Typography>
                  </Stack>
                  <ResetPasswordPage enableResetPage={setResetPassword} />
                </div>
                :
                <div>
                  <Stack sx={{ mb: 5 }}>
                    <Typography variant="h4" gutterBottom>
                      Iniciar Sesión
                    </Typography>
                  </Stack>
                  <LoginForm enableResetPage={setResetPassword} />
                </div>
            }


            <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                No tiene una cuenta?&nbsp;
                <Link variant="subtitle2" component={RouterLink} to="register">
                  Registrarse
                </Link>
              </Typography>
            </MHidden>
          </ContentStyle>
        </Container>
      </RootStyle>
    </ToastProvider>

  );
}
