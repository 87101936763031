import { LoadingButton } from "@mui/lab";
import { Button, Divider, Grid, Icon, IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from 'yup';
import SaveIcon from '@mui/icons-material/Save';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useState } from "react";
import { authenticationService } from '../../_services'

const ChangePhonePage = () => {
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const [isSendCode, setIsSendCode] = useState(false);

    const EditSchema = Yup.object().shape({
        newPhoneNumber: Yup.string().required('Este campo es obligatorio'),
        code: Yup.string().required('Este campo es obligatorio'),
    });

    const formik = useFormik({
        initialValues: {
            newPhoneNumber: '',
            code: '',
        },
        validationSchema: EditSchema,

        onSubmit: () => save(),
    })

    const { values, getFieldProps, touched, errors } = formik;

    const sendCode = () => {
        if (!values.newPhoneNumber) {
            addToast("El teléfono es obligatorio", {
                appearance: "info",
                autoDismiss: true
            })
            return false;
        }
        authenticationService.changePhone(values.newPhoneNumber).then(res => {
            if (res.success) {
                addToast("Se ha enviado el código de confirmación", {
                    appearance: "info",
                    autoDismiss: true
                })
                setIsSendCode(true);

            }
            else {
                addToast(res.errorMessage, {
                    appearance: "warning",
                    autoDismiss: true
                })
            }
        }, error => {
            console.log(error);
            addToast("No se ha podido enviar el código de confirmación", {
                appearance: "error",
                autoDismiss: true
            })
        })

    };

    const save = async () => {
        const isChanged = await authenticationService.confirmChangePhone(values.code).then(res => {
            if (res.success) {
                addToast("El número de teléfono ha sido cambiado", {
                    appearance: "success",
                    autoDismiss: true
                });
                return true;
            }

            addToast(res.errorMessage, {
                appearance: "warning",
                autoDismiss: true
            });
            return false;
        }, err => {
            console.log(err);
            addToast("Ha ocurrido un error", {
                appearance: "error",
                autoDismiss: true
            });
            return false;
        });

        if (isChanged) {
            navigate(-1);
        }

        return isChanged;
    }

    return (
        <Paper elevation={3} sx={{ p: 2, ml: 2, mr: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} xl={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item><h3>Cambiar teléfono</h3></Grid>
                    </Grid>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} xl={4}>
                                <TextField type="text" fullWidth label="Teléfono" onBlur={formik.handleBlur} value={formik.values.newPhoneNumber} name="newPhoneNumber" onChange={formik.handleChange} helperText={formik.touched.newPhoneNumber && formik.errors.newPhoneNumber} error={formik.touched.newPhoneNumber && formik.errors.newPhoneNumber} variant="outlined" required />
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4}>
                                <TextField type="text" fullWidth label="Código" onBlur={formik.handleBlur} value={formik.values.code} name="code" onChange={formik.handleChange} helperText={formik.touched.code && formik.errors.code} error={formik.touched.code && formik.errors.code} variant="outlined" required />
                            </Grid>
                        </Grid>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Button sx={{ mr: 1 }}
                                color="secondary"
                                onClick={() => navigate("/dashboard/profile")}
                                variant="contained">
                                Regresar
                            </Button>
                            {!isSendCode &&
                                <Button sx={{ mr: 1 }}
                                    color="primary"
                                    onClick={() => sendCode()}
                                    variant="contained">
                                    Solicitar
                                </Button>
                            }

                            {isSendCode &&
                                <div>
                                    <Button sx={{ mr: 1 }}
                                        color="warning"
                                        onClick={() => sendCode()}
                                        variant="contained">
                                        Reenviar
                                    </Button>
                                    <LoadingButton
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        type="submit"
                                        variant="contained"
                                        loading={formik.isSubmitting}
                                    >
                                        Guardar
                                    </LoadingButton>
                                </div>
                            }
                        </Box>
                    </form>
                </Grid>

            </Grid>

        </Paper>
    )
}

export default ChangePhonePage;