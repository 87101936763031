import * as React from 'react';
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { setStepPassportData } from '../../_redux/CreatePassportReducer';
import { servicioConsular } from '../../_helpers/enums';

export default function StepPersonalData() {
  const consularService = useSelector((s) => s.createPassport.consularService);
  const dispatch = useDispatch();
  const stepReducer = useSelector((state) => state.createPassport.stepPassportData);

  const formik = useFormik({
    initialValues: { ...stepReducer.values },
    initialTouched: { ...stepReducer.touched },
    validate: (values) => {
      const errors = {};
      if (
        !values.reasonNoAvailability &&
        (consularService === servicioConsular.Renovacion ||
          consularService === servicioConsular.Renovacion2)
      ) {
        errors.reasonNoAvailability = 'Este campo es obligatorio';
      }
      if (!values.passaportNumber) {
        errors.passaportNumber = 'Este campo es obligatorio';
      }
      if (!values.expirePassport) {
        errors.expirePassport = 'Este campo es obligatorio';
      }

      return errors;
    }
  });

  React.useEffect(() => {
    dispatch(
      setStepPassportData({
        ...stepReducer,
        values: formik.values,
        errors: formik.errors,
        touched: formik.touched
      })
    );
  }, [formik.values]);

  React.useEffect(() => {
    dispatch(setStepPassportData({ ...stepReducer, isValid: formik.isValid }));
  }, [formik.isValid]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} xl={4}>
        <TextField
          fullWidth
          label="Número del pasaporte"
          onBlur={formik.handleBlur}
          value={formik.values.passaportNumber}
          name="passaportNumber"
          onChange={formik.handleChange}
          helperText={formik.touched.passaportNumber && formik.errors.passaportNumber}
          error={formik.touched.passaportNumber && formik.errors.passaportNumber}
          variant="outlined"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} xl={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Fecha de vencimiento"
            value={formik.values.expirePassport}
            onChange={(value) => {
              formik.setFieldValue('expirePassport', value);
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                name="expirePassport"
                onBlur={formik.handleBlur}
                helperText={formik.touched.expirePassport && formik.errors.expirePassport}
                error={formik.touched.expirePassport && formik.errors.expirePassport}
                required
              />
            )}
          />
        </LocalizationProvider>
      </Grid>

      {(consularService === servicioConsular.Renovacion ||
        consularService === servicioConsular.Renovacion2) && (
        <Grid item xs={12} sm={12} xl={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} xl={12}>
              <Divider variant="middle" />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
              <h4>Pasaporte Anterior</h4>
              <FormControl
                fullWidth
                error={formik.touched.reasonNoAvailability && formik.errors.reasonNoAvailability}
              >
                <InputLabel id="reasonNoAvailability">Razón de no disponibilidad</InputLabel>
                <Select
                  labelId="reasonNoAvailability"
                  value={formik.values.reasonNoAvailability}
                  name="reasonNoAvailability"
                  label="Razón de no disponibilidad"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="1">Dañado</MenuItem>
                  <MenuItem value="2">Desactivado</MenuItem>
                  <MenuItem value="3">Erróneo</MenuItem>
                  <MenuItem value="4">Hojas Agotadas</MenuItem>
                  <MenuItem value="5">Pérdida</MenuItem>
                  <MenuItem value="6">Próximo Vencimiento</MenuItem>
                  <MenuItem value="7">Robado</MenuItem>
                  <MenuItem value="8">Vencido</MenuItem>
                  <MenuItem value="9">Visa Falsa</MenuItem>
                </Select>
                {formik.touched.reasonNoAvailability && formik.errors.reasonNoAvailability && (
                  <FormHelperText>{formik.errors.reasonNoAvailability}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
