import { URL_SERVER, AgencyId } from '../_settings/urlConfig';
import { authHeader, authHeaderTypeJson, handleResponse } from '../_helpers';

export const promoCodeService = {
    getCode
}

function getCode(code) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    const url = `${URL_SERVER}/api/v1/PromoCode/Get/get_promocode?code=${code}`;
    return fetch(url, requestOptions).then(handleResponse);
}
