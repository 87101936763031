import { URL_SERVER } from '../_settings/urlConfig';
import { authHeader, authHeaderTypeJson, handleResponse } from '../_helpers';

export const paymentCardService = {
    getCards,
    createCard,
    deleteCard
}

function getCards() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    const url = `${URL_SERVER}/api/v1/PaymentCards`;
    return fetch(url, requestOptions).then(handleResponse);
}
function createCard(firstName, lastName, billingAddress, state, city, countryIso2, zipCode, billingPhone, type, number, cvv, expiration) {
    const model = {
        firstName: firstName,
        lastName: lastName,
        billingAddress: billingAddress,
        state: state,
        city: city,
        countryIso2: countryIso2,
        zipCode: zipCode,
        billingPhone: billingPhone,
        type: type,
        number: number,
        cvv: cvv,
        expiration: expiration,
    }
    const requestOptions = { method: 'POST', headers: authHeaderTypeJson(), body: JSON.stringify(model) };
    return fetch(`${URL_SERVER}/api/v1/PaymentCards`, requestOptions).then(handleResponse);
}

function deleteCard(id) {
    const requestOptions = { method: 'DELETE', headers: authHeader() };
    return fetch(`${URL_SERVER}/api/v1/PaymentCards/${id}`, requestOptions).then(handleResponse);
}
