import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import ProfilePage from './pages/Account/ProfilePage';
import EditProfilePage from './pages/Account/EditProfilePage';
import NotFound from './pages/Page404';
import PrimerVezPage from './pages/Passports/PrimerVezPage';
import RenovarPage from './pages/Passports/RenovarPage';
import PrimerVezMenorPage from './pages/Passports/PrimerVezMenorPage';
import RenovarMenorPage from './pages/Passports/RenovarMenorPage';
import CreatePaymentCard from './pages/PaymentCards/CreatePaymentCardPage';
import ListPassportPage from './pages/Passports/ListPassportPage';
import DetailsPassportPage from './pages/Passports/DetailsPassportPage';
import ChangePhotoPage from './pages/Passports/ChangePhotoPage';
import ChangeSignaturePage from './pages/Passports/ChangeSignaturePage';
import ChangePasswordPage from './pages/Account/ChangePasswordPage';
import ChangePhonePage from './pages/Account/ChangePhonePage';
import TermsAndCondition from './pages/TermsAndCondition';
import ListPassportPendingPage from './pages/Passports/ListPassportPendingPage';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard" replace /> },
        { path: '', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'profile/edit', element: <EditProfilePage /> },
        { path: 'profile/change-password', element: <ChangePasswordPage /> },
        { path: 'profile/change-phone', element: <ChangePhonePage /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'primervez', element: <PrimerVezPage /> },
        { path: 'renovar', element: <RenovarPage /> },
        // { path: 'primervezmenor', element: <PrimerVezMenorPage /> },
        // { path: 'renovarmenor', element: <RenovarMenorPage /> },
        { path: 'paymentcard', element: <CreatePaymentCard /> },
        { path: 'passports', element: <ListPassportPage /> },
        { path: 'pending-passports', element: <ListPassportPendingPage /> },
        { path: 'passport/:id', element: <DetailsPassportPage /> },
        { path: 'passport/changePhoto/:id', element: <ChangePhotoPage /> },
        { path: 'passport/changeSignature/:id', element: <ChangeSignaturePage /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'terms_and_conditions', element: <TermsAndCondition /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
