import { configureStore } from '@reduxjs/toolkit';
import createPassportReducer from './_redux/CreatePassportReducer'
import accountReducer from './_redux/AccountReducer'
import layoutReducer from './_redux/LayoutReducer'

export const store = configureStore({
    reducer: {
        createPassport: createPassportReducer,
        account: accountReducer,
        layout: layoutReducer
    },
});