import '../../_styles/PassportList.css';
import { Container, Grid, List, Paper } from '@mui/material';
import React from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { useNavigate } from 'react-router';

import { servicioConsular } from '../../_helpers/enums';
import { passportService } from '../../_services';

const ListPassportPage = () => {
  const [passports, setPassports] = React.useState([]);

  React.useEffect(async () => {
    const data = await getPassports();
    setPassports(data);
  }, []);

  const getPassports = () => {
    return passportService.getPassports().then(
      (res) => {
        if (res?.success) return res.objects;

        return [];
      },
      (error) => {
        console.log(error);
        return [];
      }
    );
  };
  return (
    <Container>
      <h3>Mis Órdenes</h3>
      <List style={{ maxHeight: '100%', overflow: 'auto' }}>
        {passports.map((item) => {
          return <ListItem item={item} />;
        })}
      </List>
    </Container>
  );
};

const ListItem = ({ item }) => {
  const navigate = useNavigate();

  const getServicioConsular = (servicio) => {
    switch (servicio) {
      case servicioConsular.PrimerVez:
        return 'Primera Vez';
      case servicioConsular.Prorroga1:
        return 'Prorroga 1';
      case servicioConsular.Prorroga2:
        return 'Prorroga 2';
      case servicioConsular.Renovacion:
        return 'Renovación';
      default:
        return '';
    }
  };
  return (
    <Paper
      elevation={3}
      sx={{ m: 1, mb: 2, p: 1, cursor: 'pointer' }}
      onClick={() => {
        navigate(`/dashboard/passport/${item.passportId}`);
      }}
    >
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <h5>{item.clientFullName}</h5>
          <p>{getServicioConsular(item.servicioConsular)}</p>
          <p>{item.number}</p>
          <p>{moment(new Date(item.date)).format('MM/DD/YYYY')}</p>
        </Grid>
        <Grid item>
          <NumberFormat displayType="text" decimalSeparator="." value={item.amount} prefix="$" />
          <p>{item.status}</p>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ListPassportPage;
