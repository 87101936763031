import '../../_styles/CreatePaymentCardStyle.css'
import { Button, Container, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CreateCreditCard } from '../../components/paymentCard/createCreditCard'
import { paymentCardService } from '../../_services'

const CreateCreditCardPage = () => {
    const navigate = useNavigate();
    const { addToast } = useToasts()
    const [paymentCard, setPaymentCard] = useState(null);

    React.useEffect(() => {
        getCard();
    }, [])

    const onSave = () => {
        getCard();
    }

    const getCard = async () => {
        const card = await paymentCardService.getCards().then(res => {
            if (res.success) {
                if (res.objects.lenght === 0)
                    return null;

                return res.objects[0];
            }
            addToast(res.errorMessage, {
                appearance: 'info',
                autoDismiss: true,
            });
            return null;
        }, error => {
            console.log(error);
            addToast("No se ha podido obtener la tarjeta de pago", {
                appearance: 'error',
                autoDismiss: true,
            });
        })

        setPaymentCard(card);
    }

    const deleteCreditCard = (id) => {
        paymentCardService.deleteCard(id).then(res => {
            if (res.success) {
                addToast("The credit card has been deleted", {
                    appearance: 'success',
                    autoDismiss: true,
                });
                getCard();
            }
            else {
                addToast(res.errorMessage, {
                    appearance: 'info',
                    autoDismiss: true,
                });
            }
        }, error => {
            console.log(error);
            addToast("No se ha podido eliminar la tarjeta", {
                appearance: 'error',
                autoDismiss: true,
            });
        })
    }
    return (
        <Container>
            {paymentCard ?
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12} xl={6}>
                        <div className="marginBottom">
                            <h4 className="mb-4 center">Your Credit Card</h4>
                        </div>
                        <div className="marginBottom">
                            <Cards

                                expiry={paymentCard?.expiration}
                                name={`${paymentCard?.firstName} ${paymentCard?.lastName}`}
                                number={`000000000000${paymentCard?.lastFour}`}
                                issuer={paymentCard?.type}
                            />
                        </div>

                        <div className="center">
                            <Button type="button" variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => deleteCreditCard(paymentCard?.id)} >
                                Delete
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                :
                <CreateCreditCard onSuccessSubmitCallback={onSave} />
            }

            <Button sx={{ mt: 5 }} type="button" variant="contained" color="primary" startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)} >
                Regresar
            </Button>
        </Container>
    )
}

export default CreateCreditCardPage;