export const ConvertCmToFeet = (value) => {
    let val = 0;
    if (typeof value === "number") {
        val = value;
    }
    else {
        val = parseFloat(value)
    }
    let ft = val / 30.48;
    ft = Math.floor(ft);
    let p = val - (ft * 30.48);
    p = p / 2.54 / 100;
    return ft + p;
}

export const ConvertFeetToCm = (value) => {
    let val = 0;
    if (typeof value === "number") {
        val = value;
    }
    else {
        val = parseFloat(value)
    }
    const ft = Math.floor(val);
    let p = val - ft;
    p *= 100;
    let cm = ft * 30.48;
    cm += p * 2.54;
    return cm;
}