import { useNavigate } from 'react-router-dom'; // material
import { Box, Grid, Container, Typography, Hidden } from '@mui/material';
// components
import Page from '../components/Page';
import {
  AppPrimerVez,
  AppRenovar,
  AppPrimerVezMenor,
  AppRenovarMenor
} from '../components/_dashboard/app';
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const navigate = useNavigate();
  return (
    <Page title="Dashboard | Tu Pasaporte">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">¿Qué trámite desea hacer?</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={3} onClick={() => navigate('/dashboard/primervez')}>
            <AppPrimerVez />
          </Grid>
          <Grid item xs={6} sm={6} md={3} onClick={() => navigate('/dashboard/renovar')}>
            <AppRenovar />
          </Grid>
          {/*
           <Grid item xs={6} sm={6} md={3} onClick={() => navigate('/dashboard/primervezmenor')}>
            <AppPrimerVezMenor />
          </Grid>
          <Grid item xs={6} sm={6} md={3} onClick={() => navigate('/dashboard/renovarmenor')}>
            <AppRenovarMenor />
          </Grid>
          */}
        </Grid>
      </Container>
    </Page>
  );
}
