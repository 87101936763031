import { LoadingButton } from "@mui/lab";
import { Button, Divider, FormHelperText, Grid, Paper, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useParams } from "react-router";
import SaveIcon from '@mui/icons-material/Save';
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";

import { isNullOrEmpty } from "../../Tools/StringTools";
import { passportService } from "../../_services";
import { URL_AGENCIA } from "../../_settings/urlConfig";

const ChangePhotoPage = () => {
    const { id } = useParams();
    const { addToast } = useToasts();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [passport, setPassport] = useState(null);
    const [photo, setPhoto] = useState(null);

    React.useEffect(async () => {
        const data = await getPassport();
        setPassport(data);
    }, [])

    const getPassport = () => {
        return passportService.getPassport(id).then(res => {
            if (res.success)
                return res.object;

            return null;
        }, error => {
            console.log(error);
            return null;
        })
    }
    const changeInputPhoto = (input) => {
        // Assuming only image
        const file = input.currentTarget.files[0];
        const size = Math.round(file.size / 1024 / 1024);
        if (size > parseFloat(2)) {
            addToast("El tamaño máximo de la imagen es de 2 MB", {
                autoDismiss: true,
                appearance: "info",
            });
            return false;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            setPhoto(reader.result);
        };
        // TODO: concat files
    }

    const save = async () => {
        setLoading(true);
        if (isNullOrEmpty(photo)) {
            addToast("Debe elegir una imagen", {
                autoDismiss: true,
                appearance: "info",
            });
            setLoading(false);
            return false;
        }

        // submit
        const image = photo.split("base64,")[1];
        const response = await passportService.updatePhoto(id, image).then(res => {
            if (res.success)
                return true;

            addToast(res.errorMessage, {
                autoDismiss: true,
                appearance: "info",
            })
            return false;
        }, error => {
            console.log(error);
            addToast("Ha ocurridoun error", {
                autoDismiss: true,
                appearance: "error",
            })
        })

        if (response) {
            const data = await getPassport();
            setPassport(data);
            setPhoto(null);
            addToast("La foto ha sido actualizada", {
                autoDismiss: true,
                appearance: "success",
            })
        }

        setLoading(false);
    }

    return (
        <Paper elevation={3} sx={{ p: 2, ml: 2, mr: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} xl={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item><h3>Foto de Pasaporte</h3></Grid>
                        <Grid>
                            <Button
                                size="small"
                                variant="contained"
                                component="label"
                                sx={{ m: 1 }}
                            >
                                Upload File
                                <input
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={changeInputPhoto}
                                    hidden
                                />
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>
                {passport &&
                    <Grid item xs={12} sm={12} xl={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} xl={4} >
                                <h4>Foto actual:</h4>
                                {isNullOrEmpty(passport.passportPhoto) ?
                                    <span>No existe foto de pasaporte</span>
                                    :
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 150,
                                            width: 150,
                                            maxHeight: { xs: 233, md: 167 },
                                            maxWidth: { xs: 350, md: 250 },
                                        }}
                                        alt="Foto de pasaporte."
                                        src={`${URL_AGENCIA}${passport.passportPhoto}`}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4}>
                                <h4>Nueva foto:</h4>
                                {photo ?
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 150,
                                            width: 150,
                                            maxHeight: { xs: 233, md: 167 },
                                            maxWidth: { xs: 350, md: 250 },
                                        }}
                                        alt="Foto de pasaporte."
                                        src={photo}
                                    />
                                    :
                                    <span>Debe elegir una imagen</span>
                                }
                            </Grid>
                        </Grid>
                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                            <Button
                                color="secondary"
                                onClick={() => navigate(-1)}
                                variant="contained">
                                Regresar
                            </Button>
                            <LoadingButton
                                color="primary"
                                onClick={save}
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                            >
                                Actualizar
                            </LoadingButton>
                        </Stack>
                    </Grid>
                }
            </Grid>
        </Paper>
    )
}

export default ChangePhotoPage;