import { AgencyId, URL_SERVER } from '../_settings/urlConfig';
import { authHeader, authHeaderTypeJson, handleResponse } from '../_helpers';

export const logService = {
    addClient
}


function addClient(firstName, secondName, surname, secondSurname, phoneNumber, address, state, city, postalCode, email, birthDate) {
    const model = {
        PhoneNumber: phoneNumber,
        FirstName: firstName,
        SecondName: secondName,
        Surname: surname,
        SecondSurname: secondSurname,
        Email: email,
        Address: address,
        State: state,
        City: city,
        PostalCode: postalCode,
        BirthDate: birthDate
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'x-agency': AgencyId,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(model)
    };
    return fetch(`${URL_SERVER}/api/v1/Log/add_client_information`, requestOptions).then(handleResponse);
}

