import { eyeColor, sex, skinColor, hairColor, classificationMigration, categoriaProfesion, schoolLevel } from "./enums";

export const getSex = (val) => {
    switch (val) {
        case sex.Femenino:
            return "Femenino";
        case sex.Masculino:
            return "Masculino";
        default:
            return "";
    }
}

export const getEyeColor = (val) => {
    switch (val) {
        case eyeColor.Claros:
            return "Claros";
        case eyeColor.Negros:
            return "Negros";
        case eyeColor.Pardos:
            return "Pardos";
        default:
            return "";
    }
}

export const getSkinColor = (val) => {
    switch (val) {
        case skinColor.Albina:
            return "Albina";
        case skinColor.Amarilla:
            return "Amarilla";
        case skinColor.Blanca:
            return "Blanca";
        case skinColor.Mulata:
            return "Mulata";
        case skinColor.Negra:
            return "Negra";
        default:
            return "";
    }
}

export const getHairColor = (val) => {
    switch (val) {
        case hairColor.Canoso:
            return "Canoso";
        case hairColor.Castano:
            return "Castano";
        case hairColor.Negro:
            return "Negro";
        case hairColor.Otros:
            return "Otros";
        case hairColor.Rubio:
            return "Rubio";
        default:
            return "";
    }
}

export const getClassificationMigration = (val) => {
    switch (val) {
        case classificationMigration.AsuntoOficial:
            return "Asunto Oficial";
        case classificationMigration.PRE:
            return "PRE";
        case classificationMigration.PSD:
            return "PSD";
        case classificationMigration.PSI:
            return "PSI";
        case classificationMigration.PVE:
            return "PVE";
        case classificationMigration.PVT:
            return "PVT";
        case classificationMigration.PermisoEmigracion:
            return "Permiso Emigracion";
        case classificationMigration.SalidaIlegal:
            return "Salida Ilegal";
        default:
            return "";
    }
}

export const getCategoriaProfesion = (val) => {
    switch (val) {
        case categoriaProfesion.CuentaPropista:
            return "Cuenta Propista";
        case categoriaProfesion.Deporte:
            return "Deporte";
        case categoriaProfesion.Educacion:
            return "Educacion";
        case categoriaProfesion.Salud:
            return "Salud";
        case categoriaProfesion.Turismo:
            return "Turismo";
        case categoriaProfesion.Otros:
            return "Otros";
        default:
            return "";
    }
}

export const getSchoolLevel = (val) => {
    switch (val) {
        case schoolLevel.Analfabeto:
            return "Analfabeto";
        case schoolLevel.PreUniversitario:
            return "Pre-Universitario";
        case schoolLevel.Primario:
            return "Primario";
        case schoolLevel.Secundario:
            return "Secundario";
        case schoolLevel.TecnicoMedio:
            return "Tecnico Medio";
        case schoolLevel.Univeritario:
            return "Universitario";
        default:
            return "";
    }
}