import { Avatar, Button, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper } from "@mui/material";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import RoomIcon from '@mui/icons-material/Room';
import React, { useState } from "react";
import { object } from "prop-types";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import { accountService } from '../../_services'


const ProfilePage = () => {
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const [profile, setProfile] = useState(null);

    React.useEffect(async () => {
        const data = await getProfile();
        setProfile(data);
    }, [])

    const getProfile = async () => {
        return accountService.getAccount().then(res => {
            if (res.success) {
                return res.object;
            }

            addToast(res.errorMessage, {
                appearance: 'info',
                autoDismiss: true,
            });
            return null;

        }, error => {
            console.log(error);
            addToast("Ha ocurrido un error", {
                appearance: 'error',
                autoDismiss: true,
            });
            return null;
        });

    }

    return (
        <Paper elevation={3} sx={{ p: 2, ml: 2, mr: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} xl={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item><h3>Perfil</h3></Grid>
                        <Grid><Button onClick={() => navigate("/dashboard/profile/edit")}>Editar</Button></Grid>
                    </Grid>

                    <Divider />
                </Grid>
                <Grid item xs={12} sm={6} xl={6}>
                    <h5>Datos Personales</h5>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <PhoneIphoneIcon />
                            </ListItemAvatar>
                            <ListItemText primary="Teléfono" secondary={profile?.phoneNumber} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <EmailIcon />
                            </ListItemAvatar>
                            <ListItemText primary="Email" secondary={profile?.email} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <PersonIcon />
                            </ListItemAvatar>
                            <ListItemText primary="Primer Nombre" secondary={profile?.firstName} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <PersonIcon />
                            </ListItemAvatar>
                            <ListItemText primary="Segundo Nombre" secondary={profile?.secondName} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <PersonIcon />
                            </ListItemAvatar>
                            <ListItemText primary="Primer Apellido" secondary={profile?.firstLastName} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <PersonIcon />
                            </ListItemAvatar>
                            <ListItemText primary="Segundo Apellido" secondary={profile?.secondLastName} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} sm={6} xl={6}>
                    <h5>Dirección</h5>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <RoomIcon />
                            </ListItemAvatar>
                            <ListItemText primary="Country" secondary={profile?.address?.country} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <RoomIcon />
                            </ListItemAvatar>
                            <ListItemText primary="Estado" secondary={profile?.address?.state} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <RoomIcon />
                            </ListItemAvatar>
                            <ListItemText primary="Ciudad" secondary={profile?.address?.city} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <RoomIcon />
                            </ListItemAvatar>
                            <ListItemText primary="Dirección" secondary={profile?.address?.addressLine1} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <RoomIcon />
                            </ListItemAvatar>
                            <ListItemText primary="Código Postal" secondary={profile?.address?.zip} />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>

        </Paper>
    )
}

export default ProfilePage;