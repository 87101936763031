import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { IconButton } from "@mui/material";
import { useNavigate } from 'react-router';

const MenuPersonalized = ({ options }) => {
    console.log(options)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="menu"
                aria-controls="menu"
                aria-expanded={open ? true : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="menu"
                MenuListProps={{
                    'aria-labelledby': 'menu',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {options && options.map((option) => (
                    <MenuItem key={option.value} onClick={e => { handleClose(e); navigate(option.route) }}>
                        {option.value}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}

export default MenuPersonalized;