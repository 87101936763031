import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, IconButton, Paper, Stack } from '@mui/material';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopy from '@mui/icons-material/ContentCopy';
import moment from 'moment';
import { ConvertCmToFeet } from '../../Tools/unit-convert';
import { isNullOrEmpty } from '../../Tools/StringTools';
import {
  setConsularService,
  setCurrentStep,
  setDefaultValues,
  setValues,
  setRefIdSaved
} from '../../_redux/CreatePassportReducer';
import Step1 from '../../components/passport/StepClientData';
import Step2 from '../../components/passport/StepPassportData';
import Step3 from '../../components/passport/StepPersonalData';
import Step4 from '../../components/passport/StepGeneralData';
import Step5 from '../../components/passport/StepLaboralData';
import Step6 from '../../components/passport/StepReferenceCuba';
import Step7 from '../../components/passport/StepPhotoSignature';
import Step8 from '../../components/passport/StepPayment';
import { paymentType, servicioConsular } from '../../_helpers/enums';
import {
  logService,
  passportService,
  registerService,
  incompletePassportService,
  authenticationService
} from '../../_services';
import tools from '../../Tools/index';

const steps = [
  'Datos del cliente',
  'Datos del pasaporte',
  'Datos personales',
  'Datos Generales',
  'Datos Laborales',
  'Referencia en Cuba',
  'Foto de pasaporte',
  'Métodos de Pago'
];

export default function RenovarPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useSelector((s) => s.createPassport);
  const activeStep = form.currentStep;
  const { addToast } = useToasts();
  const [completed, setCompleted] = React.useState({});
  const [saving, setSaving] = React.useState(false);
  const [newUser, setNewUser] = React.useState();
  const [nextLoading, setNextLoading] = React.useState(false);
  const currentUser = authenticationService.currentUserValue;

  const {
    loadingPrimerVez,
    loadingRenovar,
    loadingPrimerVezMenor,
    loadingRenovarMenor,
    stepClientData,
    stepPassportData,
    stepPersonalData,
    stepGeneralData,
    stepLaboralData,
    stepReferenceCuba,
    stepPhotoSignature,
    stepPaymentData
  } = form;

  React.useEffect(() => {
    setStepData();
  }, []);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = async () => {
    const isValid = validateStep();
    if (isValid) {
      if (activeStep === 0) {
        if(!currentUser){
          setNextLoading(true);
          const response = await registerService.generateNewUser(
            stepClientData.values.phone,
            stepClientData.values.email,
            stepClientData.values.firstName,
            stepClientData.values.secondName,
            stepClientData.values.firstSurname,
            stepClientData.values.secondSurname,
            stepClientData.values.address,
            stepClientData.values.state,
            stepClientData.values.city,
            stepClientData.values.zip
          );
          if (response?.data?.success) {
            setNewUser({
              user: stepClientData.values.phone,
              password: response.password
            });
          }
          setNextLoading(false);
        }
        if (isMinor(stepClientData.values.dateBirth)) {
          addToast('Se va a realizar el trámite a un menor de edad.', {
            appearance: 'info',
            autoDismiss: true
          });
        }
      }

      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      dispatch(setCurrentStep(newActiveStep));
      saveForm();
    }
  };

  const handleBack = () => {
    dispatch(setCurrentStep(activeStep - 1));
  };

  const handleStep = (step) => () => {
    dispatch(setCurrentStep(step));
  };

  const handleReset = () => {
    dispatch(setCurrentStep(0));
    setCompleted({});
  };

  const validateStep = () => {
    if (activeStep === 0) {
      if (Object.keys(stepClientData.touched).length === 0) {
        addToast('Debe completar los datos', {
          appearance: 'info',
          autoDismiss: true
        });
        return false;
      }
      if (!stepClientData.isValid) {
        addToast('Debe validar que los campos estén correctos', {
          appearance: 'info',
          autoDismiss: true
        });
        return false;
      }

      logService.addClient(
        stepClientData.values.firstName,
        stepClientData.values.secondName,
        stepClientData.values.firstSurname,
        stepClientData.values.secondSurname,
        stepClientData.values.phone,
        stepClientData.values.address,
        stepClientData.values.state,
        stepClientData.values.city,
        stepClientData.values.zip,
        stepClientData.values.email,
        stepClientData.values.dateBirth
      );
    }
    if (activeStep === 1) {
      if (Object.keys(stepPassportData.touched).length === 0) {
        addToast('Debe completar los datos', {
          appearance: 'info',
          autoDismiss: true
        });
        return false;
      }
      if (!stepPassportData.isValid) {
        addToast('Debe validar que los campos estén correctos', {
          appearance: 'info',
          autoDismiss: true
        });
        return false;
      }
    } else if (activeStep === 2) {
      console.log(stepPersonalData.errors);
      if (Object.keys(stepPersonalData.touched).length === 0) {
        addToast('Debe completar los datos', {
          appearance: 'info',
          autoDismiss: true
        });
        return false;
      }
      if (!stepPersonalData.isValid) {
        addToast('Debe validar que los campos estén correctos', {
          appearance: 'info',
          autoDismiss: true
        });
        return false;
      }
    } else if (activeStep === 3) {
      if (Object.keys(stepGeneralData.touched).length === 0) {
        addToast('Debe completar los datos', {
          appearance: 'info',
          autoDismiss: true
        });
        return false;
      }
      if (!stepGeneralData.isValid) {
        addToast('Debe validar que los campos estén correctos', {
          appearance: 'info',
          autoDismiss: true
        });
        return false;
      }
    } else if (activeStep === 4) {
      if (Object.keys(stepLaboralData.touched).length === 0) {
        addToast('Debe completar los datos', {
          appearance: 'info',
          autoDismiss: true
        });
        return false;
      }
      if (!stepLaboralData.isValid) {
        addToast('Debe validar que los campos estén correctos', {
          appearance: 'info',
          autoDismiss: true
        });
        return false;
      }
    } else if (activeStep === 5) {
      if (Object.keys(stepReferenceCuba.touched).length === 0) {
        addToast('Debe completar los datos', {
          appearance: 'info',
          autoDismiss: true
        });
        return false;
      }
      if (!stepReferenceCuba.isValid) {
        addToast('Debe validar que los campos estén correctos', {
          appearance: 'info',
          autoDismiss: true
        });
        return false;
      }
    } else if (activeStep === 6) {
      if (isNullOrEmpty(stepPhotoSignature.values.passportPhoto)) {
        addToast('Debe adjuntar una foto de pasaporte', {
          appearance: 'info',
          autoDismiss: true
        });
        return false;
      }
      if (isNullOrEmpty(stepPhotoSignature.values.signature)) {
        addToast('Debe firmar antes de continuar', {
          appearance: 'info',
          autoDismiss: true
        });
        return false;
      }
    }
    return true;
  };

  const saveForm = async () => {
    if(!currentUser){
      return {
        success : false
      }
    }
    const data = { ...form };

    let savedData;
    if (form.refIdSaved) {
      savedData = await incompletePassportService.update(form.refIdSaved, data);
    } else {
      savedData = await incompletePassportService.create(data);
    }

    if (savedData.success) {
      dispatch(setRefIdSaved(savedData.object.id));
    }

    return savedData;
  };

  const isMinor = (date) => {
    const dateBirth = moment(date);
    const now = moment();
    const days = now.diff(dateBirth, 'days');
    return days < 365 * 16
  }

  const saveSteps = async () => {
    const savedData = await saveForm();
    if (savedData.success) {
      addToast('Los datos han sido guardados', {
        appearance: 'success',
        autoDismiss: true
      });
    } else {
      addToast('Los datos no han podido ser guardados', {
        appearance: 'warning',
        autoDismiss: true
      });
    }
  };

  const setStepData = async () => {
    if (form.refIdSaved != null && form.consularService === servicioConsular.None) {
      const data = await incompletePassportService.get(form.refIdSaved);
      if (data.success) {
        const dataValues = JSON.parse(data.object.data);
        setValues({
          ...data,
          currentStep: 0,
          loadingPrimerVez: 1,
          loadingRenovar: 0,
          loadingPrimerVezMenor: 1,
          loadingRenovarMenor: 1
        });
        dispatch(setConsularService(servicioConsular.Renovacion));
        return;
      }
    }
    dispatch(
      setValues({
        ...form,
        currentStep: activeStep,
        loadingPrimerVez: 1,
        loadingRenovar: 0,
        loadingPrimerVezMenor: 1,
        loadingRenovarMenor: 1
      })
    );
    dispatch(setConsularService(servicioConsular.Renovacion));
  };

  const deleteStepsData = async () => {
    await incompletePassportService.remove(form.refIdSaved);
  };

  const submit = () => {
    setSaving(true);

    if (!form.stepPaymentData.values.agree) {
      addToast('Debe aceptar términos y condiciones', {
        appearance: 'info',
        autoDismiss: true
      });
      setSaving(false);
      return false;
    }

    const photo = form.stepPhotoSignature.values.passportPhoto.split('base64,')[1];
    const signature = form.stepPhotoSignature.values.signature.split('base64,')[1];

    const dataPassport = {
      ...form.stepClientData.values,
      passaportNumber: form.stepPassportData.values.passaportNumber,
      expirePassport: form.stepPassportData.values.expirePassport,
      reasonNoAvailability: form.stepPassportData.values.reasonNoAvailability,
      country: 'United States',
      father: form.stepPersonalData.values.father,
      mother: form.stepPersonalData.values.mother,
      height: ConvertCmToFeet(form.stepPersonalData.values.height).toFixed(2),
      // specialCharacter: "string",
      sex: form.stepPersonalData.values.gender,
      colorEyes: form.stepPersonalData.values.eyeColor,
      skinColor: form.stepPersonalData.values.skinColor,
      hairColor: form.stepPersonalData.values.hairColor,
      classificationMigration: form.stepGeneralData.values.classificationMigration,
      departureDate: form.stepGeneralData.values.departureDate,
      countryOfBirth:
        form.stepGeneralData.values.checkCountry === '1'
          ? form.stepGeneralData.values.countryOfBirth
          : 'Cuba',
      provinceBirth: form.stepGeneralData.values.provinceBirth,
      municipalityBirth: form.stepGeneralData.values.municipalityBirth,
      ...form.stepLaboralData.values,
      firstNameReference: form.stepReferenceCuba.values.firstNameReference,
      secondNameReference: form.stepReferenceCuba.values.secondNameReference,
      surnameReference: form.stepReferenceCuba.values.surnameReference,
      secondSurnameReference: form.stepReferenceCuba.values.secondSurnameReference,
      addressReference: form.stepReferenceCuba.values.addressReference,
      provinceReference: form.stepReferenceCuba.values.provinceReference,
      municipalityReference: form.stepReferenceCuba.values.municipalityReference,
      familyRelationship: form.stepReferenceCuba.values.familyRelationship,
      passportPhoto: photo,
      signature: signature
    };

    const data = {
      paymentCardId: form.stepPaymentData.selectedPayment.paymentCardId,
      promoCode: form.stepPaymentData.values.promoCode,
      zelleName: form.stepPaymentData.values.zelleName,
      paymentType: form.stepPaymentData.selectedPayment.type,
      total: form.stepPaymentData.values.amount,
      servicioConsular: isMinor(form.stepClientData.values.dateBirth) ? servicioConsular.Renovacion2 : servicioConsular.Renovacion,
    };

    if(isMinor(form.stepClientData.values.dateBirth)){
      data.passportRenovarMenor = dataPassport;
    }
    else{
      data.passportRenovar = dataPassport;
    }

    if (isNullOrEmpty(data.paymentCardId)) {
      data.paymentCardId = '00000000-0000-0000-0000-000000000000';
    }

    if (
      data.paymentType !== paymentType.CreditCard &&
      data.paymentType !== paymentType.Zelle &&
      data.paymentType !== paymentType.None
    ) {
      addToast('Debe elegir un tipo de pago', {
        appearance: 'info',
        autoDismiss: true
      });
      setSaving(false);
      return false;
    }

    if (data.paymentType === paymentType.CreditCard && isNullOrEmpty(data.paymentCardId)) {
      addToast('La tarjeta de pago no es correcta', {
        appearance: 'info',
        autoDismiss: true
      });
      setSaving(false);
      return false;
    }

    if (data.paymentType === paymentType.Zelle && isNullOrEmpty(data.zelleName)) {
      addToast('El nombre de zelle no es correcto', {
        appearance: 'info',
        autoDismiss: true
      });
      setSaving(false);
      return false;
    }

    passportService.createPassport(data).then(
      (res) => {
        if (res.success) {
          addToast('El pasaporte ha sido creado', {
            appearance: 'success',
            autoDismiss: true
          });
          dispatch(setDefaultValues());
          deleteStepsData();
          navigate('/dashboard/passports');
        } else {
          addToast(res.errorMessage, {
            appearance: 'info',
            autoDismiss: true
          });
        }
        setSaving(false);
      },
      (error) => {
        console.log(error);
        setSaving(false);
        addToast('Ha ocurrido un error', {
          appearance: 'error',
          autoDismiss: true
        });
      }
    );
  };

  return (
    <Stack spacing={2}>
      {newUser && (
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h6">Se ha creado un usuario para usted!</Typography>
          <Typography>Usuario: {newUser.user}</Typography>
          <Stack direction="row">
            <Typography sx={{ paddingTop: 1 }}>Contraseña: {newUser.password}</Typography>
            <IconButton
              aria-label="copy to clipboard"
              onClick={() => tools.copyToClipboard(newUser.password)}
            >
              <ContentCopy fontSize="small" />
            </IconButton>
          </Stack>
        </Paper>
      )}
      <Paper elevation={3} sx={{ p: 2, ml: 2, mr: 2 }}>
        <h3>Pasaporte renovación</h3>
        <Box sx={{ width: '100%', mt: 3 }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)} />
              </Step>
            ))}
          </Stepper>
          <div>
            {allStepsCompleted() ? (
              <div>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </div>
            ) : (
              <div>
                <Container sx={{ pt: 3 }}>
                  <div>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <h3 className="marginBottom">{steps[activeStep]}</h3>
                      {currentUser && (
                        <IconButton aria-label="delete" onClick={saveSteps}>
                          <SaveIcon />
                        </IconButton>
                      )}
                    </Stack>
                    {activeStep === 0 && !loadingRenovar && <Step1 />}
                    {activeStep === 1 && !loadingRenovar && <Step2 />}
                    {activeStep === 2 && !loadingRenovar && <Step3 />}
                    {activeStep === 3 && !loadingRenovar && <Step4 />}
                    {activeStep === 4 && !loadingRenovar && <Step5 />}
                    {activeStep === 5 && !loadingRenovar && <Step6 />}
                    {activeStep === 6 && !loadingRenovar && <Step7 />}
                    {activeStep === 7 && !loadingRenovar && <Step8 />}
                  </div>
                </Container>

                <Stack
                  sx={{ mt: 2 }}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Button
                    variant="outlined"
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>

                  {activeStep === totalSteps() - 1 ? (
                    <LoadingButton
                      loadingPrimerVez={saving}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                      sx={{ mr: 1 }}
                      onClick={submit}
                    >
                      Pagar
                    </LoadingButton>
                  ) : nextLoading ? (
                    <LoadingButton
                      loading={nextLoading}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                      sx={{ mr: 1 }}
                    >
                      Next
                    </LoadingButton>
                  ) : (
                    <Button variant="outlined" onClick={handleNext} sx={{ mr: 1 }}>
                      Next
                    </Button>
                  )}
                </Stack>
              </div>
            )}
          </div>
        </Box>
      </Paper>
    </Stack>
  );
}
