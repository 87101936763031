import { URL_SERVER } from '../_settings/urlConfig';
import { authHeader, authHeaderTypeJson, handleResponse } from '../_helpers';

export const accountService = {
    getAccountStatus,
    getAccount,
    updateAccount
}

function getAccountStatus() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    const url = `${URL_SERVER}/api/v1/Accounts/status`;
    return fetch(url, requestOptions).then(handleResponse);
}

function getAccount() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    const url = `${URL_SERVER}/api/v1/Accounts`;
    return fetch(url, requestOptions).then(handleResponse);
}

function updateAccount(model) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeaderTypeJson(),
        body: JSON.stringify(model)
    };
    const url = `${URL_SERVER}/api/v1/Accounts`;
    return fetch(url, requestOptions).then(handleResponse);
}