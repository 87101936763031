import { AgencyId, URL_SERVER } from '../_settings/urlConfig';
import { authHeader, authHeaderTypeJson, handleResponse } from '../_helpers';
import { authenticationService } from './authentication.service';

async function register(
  phoneNumber,
  password,
  firstName,
  secondName,
  firstLastName,
  secondLastName,
  email,
  address,
  state,
  city,
  zip
) {
  const model = {
    phoneNumber: phoneNumber,
    password: password,
    firstName: firstName,
    secondName: secondName,
    firstLastName: firstLastName,
    secondLastName: secondLastName,
    email: email,
    addressLine1: address,
    state: state,
    city: city,
    zip: zip
  };
  const requestOptions = {
    method: 'POST',
    headers: {
      'x-agency': AgencyId,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(model)
  };
  const response = await fetch(`${URL_SERVER}/api/v1/Register?confirmPhone=false`, requestOptions).then(
    handleResponse
  );
  return response;
}

const existUser = async (phoneNumber) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'x-agency': AgencyId }
  };

  const response = await fetch(
    `${URL_SERVER}/api/v1/register/exist_user?phoneNumber=${phoneNumber}`,
    requestOptions
  );
  return handleResponse(response);
};

const generateNewUser = async (
  phoneNumber,
  email,
  firstName,
  secondName,
  firstSurname,
  secondSurname,
  address,
  state,
  city,
  zip
) => {
  try {
    const exist = await existUser(phoneNumber);
    if (exist.object) return;

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 8;
    let password = '';

    for (let i = 0; i < length; i += 1) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }

    const response = await register(
      phoneNumber,
      password,
      firstName,
      secondName,
      firstSurname,
      secondSurname,
      email,
      address,
      state,
      city,
      zip
    );

    if (response?.success) {
      await authenticationService.login(phoneNumber, password);
    }

    return {
      password: password,
      data: response
    };
  } catch (error) {
    console.log(error);
  }
};

export const registerService = {
  register,
  existUser,
  generateNewUser
};
