import { Backdrop, Button, Divider, Grid, IconButton, Paper, Stack } from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate, useParams } from "react-router";
import { useToasts } from 'react-toast-notifications';
import React, { useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";
import { Box } from "@mui/system";


import { getCategoriaProfesion, getClassificationMigration, getEyeColor, getHairColor, getSchoolLevel, getSex, getSkinColor } from '../../_helpers/getValuesEnums'
import { URL_AGENCIA } from '../../_settings/urlConfig'
import { passportService } from '../../_services'
import { isNullOrEmpty } from "../../Tools/StringTools";
import MenuPersonalized from "../../components/MenuPersonalized";

const DetailsPassportPage = () => {
    const navigate = useNavigate();

    const { id } = useParams();
    const { addToast } = useToasts()
    const [passport, setPassport] = useState(null);

    const menuOptions = [
        {
            value: "Modificar Foto",
            route: `/dashboard/passport/changePhoto/${id}`
        },
        {
            value: "Modificar Firma",
            route: `/dashboard/passport/changeSignature/${id}`
        },
    ]

    React.useEffect(async () => {
        const data = await getPassport();
        setPassport(data);
    }, [])

    const getPassport = () => {
        return passportService.getPassport(id).then(res => {
            if (res.success)
                return res.object;

            return null;
        }, error => {
            console.log(error);
            return null;
        })
    }

    const print = async () => {
        const data = await passportService.print(id).then(res => {
            return res;
        }, err => {
            console.log(err);
            return null;
        });

        if (data != null) {
            const fileName = 'document.pdf';
            const byteCharacters = atob(data);
            const byteNumber = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i += 1) {
                byteNumber[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumber);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
        }
        else {
            addToast("No se ha podido imprimir la orden", {
                appearance: 'info',
                autoDismiss: true,
            });
        }
    }

    return (
        <Paper elevation={3} sx={{ p: 2, ml: 2, mr: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} xl={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item><h3>Detalles</h3></Grid>
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center">
                                <Grid item>
                                    <IconButton aria-label="Imprimir Órden" component="span" onClick={() => print()}>
                                        <PrintIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <MenuPersonalized options={menuOptions} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>
                {passport &&
                    <Grid item xs={12} sm={12} xl={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} xl={4} >
                                <h4><u>Datos de Órden</u></h4>
                                <div><strong>Fecha solicitud: </strong><em>{moment(new Date(passport.createdAt)).format("L")}</em></div>
                                <div><strong>Número órden: </strong><em>{passport.number}</em></div>
                                <div><strong>Estado: </strong><em>{passport.status}</em></div>
                                <div><strong>Total: </strong><em>${passport.total}</em></div>
                                <div><strong>Pagado: </strong><em>${passport.paid}</em></div>
                                <div><strong>Balance: </strong><em>${passport.debit}</em></div>
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4} >
                                <h4><u>Datos del Cliente</u></h4>
                                <div><strong>Primer Nombre: </strong><em>{passport.firstName}</em></div>
                                <div><strong>Segundo Nombre: </strong><em>{passport.secondName}</em></div>
                                <div><strong>Primer Apellido: </strong><em>{passport.firstSurname}</em></div>
                                <div><strong>Teléfono: </strong><em>{passport.phone}</em></div>
                                <div><strong>Dirección: </strong><em>{passport.address}</em></div>
                                <div><strong>Estado: </strong><em>{passport.state}</em></div>
                                <div><strong>Ciudad: </strong><em>{passport.city}</em></div>
                                <div><strong>Zip: </strong><em>{passport.zip}</em></div>
                                <div><strong>Email: </strong><em>{passport.email}</em></div>
                                <div><strong>Fecha Nacimiento: </strong><em>{moment(new Date(passport.dateBirth)).format("L")}</em></div>
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4} >
                                <h4><u>Datos Personales</u></h4>
                                <div><strong>Nombre padre: </strong><em>{passport.father}</em></div>
                                <div><strong>Nombre madre: </strong><em>{passport.mother}</em></div>
                                <div><strong>Estatura (pies): </strong><em>{passport.height}</em></div>
                                <div><strong>Sexo: </strong><em>{getSex(passport.sex)}</em></div>
                                <div><strong>Color de ojos: </strong><em>{getEyeColor(passport.colorEyes)}</em></div>
                                <div><strong>Color de piel: </strong><em>{getSkinColor(passport.skinColor)}</em></div>
                                <div><strong>Color de cabello: </strong><em>{getHairColor(passport.hairColor)}</em></div>
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4} >
                                <h4><u>Datos Generales</u></h4>
                                <div><strong>Clasificación migratoria: </strong><em>{getClassificationMigration(passport.classificationMigration)}</em></div>
                                <div><strong>Fecha de Salida: </strong><em>{moment(new Date(passport.departureDate)).format("L")}</em></div>
                                <div><strong>País: </strong><em>{passport.countryOfBirth}</em></div>
                                {passport.countryOfBirth === "Cuba" ?
                                    <div>
                                        <div><strong>Provincia: </strong><em>{passport.provinceBirth}</em></div>
                                        <div><strong>Municipio: </strong><em>{passport.municipalityBirth}</em></div>
                                    </div>
                                    :
                                    <div>
                                        <div><strong>Estado: </strong><em>{passport.provinceBirth}</em></div>
                                        <div><strong>Ciudad: </strong><em>{passport.municipalityBirth}</em></div>
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4} >
                                <h4><u>Datos Laborales</u></h4>
                                <div><strong>Nombre del centro de trabajo: </strong><em>{passport.jobCenterName}</em></div>
                                <div><strong>Profesión: </strong><em>{passport.profession}</em></div>
                                <div><strong>Ocupación: </strong><em>{passport.occupation}</em></div>
                                <div><strong>Categoría de profesión: </strong><em>{getCategoriaProfesion(passport.categoryProfession)}</em></div>
                                <div><strong>Nivel escolar: </strong><em>{getSchoolLevel(passport.schoolLevel)}</em></div>
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4} >
                                <h4><u>Referencia en cuba</u></h4>
                                <div><strong>Primer Nombre: </strong><em>{passport.firstNameReference}</em></div>
                                <div><strong>Segundo Nombre: </strong><em>{passport.secondNameReference}</em></div>
                                <div><strong>Primer Apellido: </strong><em>{passport.surnameReference}</em></div>
                                <div><strong>Segundo Apellido: </strong><em>{passport.secondSurnameReference}</em></div>
                                <div><strong>Dirección: </strong><em>{passport.addressReference}</em></div>
                                <div><strong>Provincia: </strong><em>{passport.provinceReference}</em></div>
                                <div><strong>Municipio: </strong><em>{passport.municipalityReference}</em></div>
                                <div><strong>Relación familiar: </strong><em>{passport.familyRelationship}</em></div>
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4} >
                                <h4><u>Direcciones en Cuba</u></h4>
                                <h5><u>Direccion (1)</u></h5>
                                <div><strong>Dirección: </strong><em>{passport.addressCuba1}</em></div>
                                <div><strong>Provincia: </strong><em>{passport.provinceCuba1}</em></div>
                                <div><strong>Municipio: </strong><em>{passport.cityCuba1}</em></div>
                                <div><strong>Desde: </strong><em>{moment(new Date(passport.from1)).format("L")}</em></div>
                                <div><strong>Hasta: </strong><em>{moment(new Date(passport.to1)).format("L")}</em></div>
                                <h5><u>Direccion (2)</u></h5>
                                <div><strong>Dirección: </strong><em>{passport.addressCuba2}</em></div>
                                <div><strong>Provincia: </strong><em>{passport.provinceCuba2}</em></div>
                                <div><strong>Municipio: </strong><em>{passport.cityCuba2}</em></div>
                                <div><strong>Desde: </strong><em>{moment(new Date(passport.from2)).format("L")}</em></div>
                                <div><strong>Hasta: </strong><em>{moment(new Date(passport.to2)).format("L")}</em></div>
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4} >
                                <h4><u>Foto pasaporte:</u></h4>
                                {isNullOrEmpty(passport.passportPhoto) ?
                                    <span>No existe foto de pasaporte</span>
                                    :
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 150,
                                            width: 150,
                                            maxHeight: { xs: 233, md: 167 },
                                            maxWidth: { xs: 350, md: 250 },
                                        }}
                                        alt="Foto de pasaporte."
                                        src={`${URL_AGENCIA}${passport.passportPhoto}`}
                                    />
                                }

                                <h4><u>Firma:</u></h4>
                                {isNullOrEmpty(passport.signature) ?
                                    <span>No existe firma</span>
                                    :
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 75,
                                            width: 150,
                                            maxHeight: { xs: 233, md: 167 },
                                            maxWidth: { xs: 350, md: 250 },
                                        }}
                                        alt="Firma del cliente."
                                        src={`${URL_AGENCIA}${passport.signature}`}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Button
                    color="secondary"
                    onClick={() => navigate("/dashboard/passports")}
                    variant="contained">
                    Regresar
                </Button>
            </Stack>
        </Paper>
    )
}


export default DetailsPassportPage;