import { LoadingButton } from "@mui/lab";
import { Button, Divider, Grid, Icon, IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from 'yup';
import SaveIcon from '@mui/icons-material/Save';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useState } from "react";
import { authenticationService } from '../../_services'

const ResetPasswordPage = ({ enableResetPage }) => {
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const [isSendCode, setIsSendCode] = useState(false);

    const EditSchema = Yup.object().shape({
        phone: Yup.string().required('Este campo es obligatorio'),
        code: Yup.string().required('Este campo es obligatorio'),
        newPassword: Yup.string().required('Este campo es obligatorio'),
        confirmNewPassord: Yup.string().oneOf([Yup.ref('newPassword'), null], 'La contraseña no coincide')
    });

    const formik = useFormik({
        initialValues: {
            phone: '',
            code: '',
            newPassword: '',
            confirmNewPassord: '',
        },
        validationSchema: EditSchema,

        onSubmit: () => save(),
    })

    const { values, getFieldProps, touched, errors } = formik;


    const save = async () => {
        const isChanged = await authenticationService.confirmResetPassword(values.code, values.phone, values.newPassword).then(res => {
            if (res.success) {
                addToast("Su contraseña ha sido actualizada", {
                    appearance: "success",
                    autoDismiss: true
                });
                return true;
            }

            addToast(res.errorMessage, {
                appearance: "warning",
                autoDismiss: true
            });
            return false;
        }, err => {
            console.log(err);
            addToast("Ha ocurrido un error", {
                appearance: "error",
                autoDismiss: true
            });
            return false;
        });

        if (isChanged) {
            enableResetPage(false)
        }

        return isChanged;
    }

    const sendCode = async () => {
        if (!values.phone) {
            addToast("El teléfono es obligatorio", {
                autoDismiss: true,
                appearance: "warning"
            })
            return false;
        }

        const response = await authenticationService.resetPassword(values.phone).then(res => {
            if (res.success) {
                addToast("Se ha enviado un código de confirmación", {
                    autoDismiss: true,
                    appearance: "info"
                });
                return true;
            }

            addToast(res.errorMessage, {
                autoDismiss: true,
                appearance: "warning"
            });
            return false;
        }, error => {
            console.log(error);
            addToast("No se ha podido enviar el código de confirmación", {
                autoDismiss: true,
                appearance: "error"
            });
            return false;
        });

        if (response)
            setIsSendCode(true);

        return response;
    }

    return (
        <Paper >
            <Grid container >
                <Grid item xs={12} sm={12} xl={12}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} xl={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={10} sm={10} xl={10} >
                                        <TextField type="text" fullWidth label="Teléfono" onBlur={formik.handleBlur} value={formik.values.phone} name="phone" onChange={formik.handleChange} helperText={formik.touched.phone && formik.errors.phone} error={formik.touched.phone && formik.errors.phone} variant="outlined" required />
                                    </Grid>
                                    <Grid item xs={2} sm={2} xl={2}>
                                        <Button sx={{ mr: 1 }} fullWidth
                                            color="warning"
                                            onClick={() => sendCode()}
                                            variant="contained">
                                            Solicitar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} xl={12}>
                                <TextField type="text" fullWidth label="Código" disabled={!isSendCode} onBlur={formik.handleBlur} value={formik.values.code} name="code" onChange={formik.handleChange} helperText={formik.touched.code && formik.errors.code} error={formik.touched.code && formik.errors.code} variant="outlined" required />
                            </Grid>
                            <Grid item xs={12} sm={12} xl={12}>
                                <TextField type="password" fullWidth label="Contraseña Nueva" disabled={!isSendCode} onBlur={formik.handleBlur} value={formik.values.newPassword} name="newPassword" onChange={formik.handleChange} helperText={formik.touched.newPassword && formik.errors.newPassword} error={formik.touched.newPassword && formik.errors.newPassword} variant="outlined" required />
                            </Grid>
                            <Grid item xs={12} sm={12} xl={12}>
                                <TextField type="password" fullWidth label="Confirmar contraseña" disabled={!isSendCode} onBlur={formik.handleBlur} value={formik.values.confirmNewPassord} name="confirmNewPassord" onChange={formik.handleChange} helperText={formik.touched.confirmNewPassord && formik.errors.confirmNewPassord} error={formik.touched.confirmNewPassord && formik.errors.confirmNewPassord} variant="outlined" required />
                            </Grid>
                        </Grid>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Button sx={{ mr: 1 }}
                                color="secondary"
                                onClick={() => enableResetPage(false)}
                                variant="contained">
                                Login
                            </Button>
                            {isSendCode &&

                                <div>
                                    <Button sx={{ mr: 1 }}
                                        color="warning"
                                        onClick={() => sendCode()}
                                        variant="contained">
                                        Reenviar
                                    </Button>
                                    <LoadingButton
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        type="submit"
                                        variant="contained"
                                        loading={formik.isSubmitting}
                                    >
                                        Confirmar
                                    </LoadingButton>
                                </div>

                            }

                        </Box>
                    </form>
                </Grid>

            </Grid>

        </Paper >
    )
}

export default ResetPasswordPage;