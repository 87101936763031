import { URL_SERVER } from '../_settings/urlConfig';
import { authHeader, authHeaderTypeJson, handleResponse } from '../_helpers';

export const discoveryService = {
    getProvinces,
    getCitiesByStateCode
}

function getProvinces() {
    const requestOptions = {
        method: 'GET',
    };
    const url = `${URL_SERVER}/api/v1/Discovery/GetProvinces/provinces`;
    return fetch(url, requestOptions).then(handleResponse);
}

function getCitiesByStateCode(stateCode) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    const url = `${URL_SERVER}/api/v1/Discovery/GetCities/cities/${stateCode}`;
    return fetch(url, requestOptions).then(handleResponse);
}
