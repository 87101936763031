import { authenticationService } from '../_services';
import { History } from '../App';

export function handleResponse(response) {
  if (!response.ok) {
    if ([401, 403].indexOf(response.status) !== -1) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      authenticationService.logout();
      History.navigate('/login');
      return;
    }
    return new Error(response.statusText);
  }

  const data = response.json();
  return data;
}
