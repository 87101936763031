import React, { useState, useEffect } from 'react'
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import NumberFormat from 'react-number-format';
import ReactFlagsSelect from 'react-flags-select';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux'
import { Button, Container, Grid, Step, StepLabel, Stepper, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { setBlockUI } from '../../_redux/LayoutReducer'
import { getOnlyNumber, isNullOrEmpty } from '../../Tools/StringTools';
import { paymentCardService } from '../../_services';



export const CreateCreditCard = ({ cancelCallback, onSuccessSubmitCallback }) => {
    const { addToast } = useToasts();
    const [saving, setSaving] = useState(false);
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            billingAddress: '',
            state: '',
            city: '',
            zipCode: '',
            billingPhone: '',
            type: '',
            number: '',
            cvc: '',
            expiration: '',
            country: 'US'
        },
        validate: values => {
            const errors = {};
            if (!values.firstName) {
                errors.firstName = 'Este campo es obligatorio'
            }
            if (!values.lastName) {
                errors.lastName = 'Este campo es obligatorio'
            }
            if (!values.billingAddress) {
                errors.billingAddress = 'Este campo es obligatorio'
            }
            if (!values.state) {
                errors.state = 'Este campo es obligatorio'
            }
            if (!values.city) {
                errors.city = 'Este campo es obligatorio'
            }
            if (!values.zipCode) {
                errors.zipCode = 'Este campo es obligatorio'
            }
            if (!values.billingPhone) {
                errors.billingPhone = 'Este campo es obligatorio'
            }
            else {
                const phone = getOnlyNumber(values.billingPhone);
                if (phone.length !== 10) {
                    errors.billingPhone = 'El número de teléfono es incorrecto'
                }
            }
            if (!values.number) {
                errors.number = 'Este campo es obligatorio'
            }
            else {
                const number = getOnlyNumber(values.number);
                if (number.length !== 16) {
                    errors.number = 'El número de tarjeta es incorrecto'
                }
            }
            if (!values.cvc) {
                errors.cvc = 'Este campo es obligatorio'
            }
            if (!values.expiration) {
                errors.expiration = 'Este campo es obligatorio'
            }

            return errors;
        },
        onSubmit: save,
    })

    const steps = [
        "Información de la tarjeta",
        "Dirección del cliente",
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());;

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    async function save() {
        setSaving(true);
        const phone = getOnlyNumber(formik.values.billingPhone);
        return paymentCardService.createCard(formik.values.firstName, formik.values.lastName,
            formik.values.billingAddress, formik.values.state, formik.values.city, formik.values.country, formik.values.zipCode,
            phone, formik.values.type, formik.values.number, formik.values.cvc, formik.values.expiration).then(res => {
                setSaving(false);
                if (res.success) {
                    addToast("La tarjeta de crédito ha sido creada", {
                        appearance: 'success',
                        autoDismiss: true,
                    });
                    onSuccessSubmitCallback(res.object.id);
                }
                else {
                    addToast(res.errorMessage, {
                        appearance: 'info',
                        autoDismiss: true,
                    });
                }
            }, error => {
                console.log(error);
                setSaving(false);
                addToast("A ocurrido un error", {
                    appearance: 'error',
                    autoDismiss: true,
                });
            })
    }

    function cardCallBack(param) {
        const cardType = param.issuer;
        formik.setFieldValue('type', cardType);
    }

    const step1 =
        <Grid container spacing={2}>
            <Grid item md={6} xs={12} className="mb-3">
                <Cards
                    cvc={formik.values.cvc}
                    expiry={formik.values.expiration}
                    focused={formik.values.focus}
                    name={`${formik.values.firstName} ${formik.values.lastName}`}
                    number={formik.values.number}
                    callback={(e) => cardCallBack(e)}
                />
            </Grid>
            <Grid item md="6">
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <NumberFormat
                            format="#### #### #### ####"
                            label="Credit Card Number"
                            value={formik.values.number}
                            name="number"
                            customInput={TextField}
                            variant="outlined"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            helperText={formik.touched.number && formik.errors.number}
                            error={formik.touched.number && formik.errors.number}
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} xs={12} >
                        <TextField
                            size="small"
                            label="First Name"
                            value={formik.values.firstName}
                            name="firstName"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                            error={formik.touched.firstName && formik.errors.firstName}
                            variant="outlined" fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12} >
                        <TextField
                            size="small"
                            label="Last Name"
                            value={formik.values.lastName}
                            name="lastName"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                            error={formik.touched.lastName && formik.errors.lastName}
                            variant="outlined" fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12} >
                        <NumberFormat
                            label="Valid Thru"
                            format="##/##"
                            value={formik.values.expiration}
                            name="expiration"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            helperText={formik.touched.expiration && formik.errors.expiration}
                            error={formik.touched.expiration && formik.errors.expiration}
                            customInput={TextField}
                            variant="outlined"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} xs={12} >
                        <NumberFormat
                            label="CVC"
                            format="###"
                            value={formik.values.cvc}
                            name="cvc"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            helperText={formik.touched.cvc && formik.errors.cvc}
                            error={formik.touched.cvc && formik.errors.cvc}
                            customInput={TextField}
                            variant="outlined"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    const step2 =
        <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
                <ReactFlagsSelect
                    selected={formik.values.country}
                    onSelect={code => formik.setFieldValue('country', code)}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    size="small"
                    label="State"
                    value={formik.values.state}
                    name="state"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.touched.state && formik.errors.state}
                    error={formik.touched.state && formik.errors.state}
                    variant="outlined" fullWidth />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    size="small"
                    label="City"
                    value={formik.values.city}
                    name="city"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.touched.city && formik.errors.city}
                    error={formik.touched.city && formik.errors.city}
                    variant="outlined" fullWidth />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    size="small"
                    label="Address"
                    value={formik.values.billingAddress}
                    name="billingAddress"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.touched.billingAddress && formik.errors.billingAddress}
                    error={formik.touched.billingAddress && formik.errors.billingAddress}
                    variant="outlined" fullWidth />
            </Grid>
            <Grid item md={6} xs={12}>
                <NumberFormat
                    label="Phone Number"
                    format="(###) ###-####"
                    value={formik.values.billingPhone}
                    name="billingPhone"
                    customInput={TextField}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.touched.billingPhone && formik.errors.billingPhone}
                    error={formik.touched.billingPhone && formik.errors.billingPhone}

                />
            </Grid>
            <Grid item md={6} xs={12}>
                <NumberFormat
                    label="Zip Code"
                    format="#####"
                    value={formik.values.zipCode}
                    name="zipCode"
                    customInput={TextField}
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                    error={formik.touched.zipCode && formik.errors.zipCode}
                    fullWidth
                    size="small" />
            </Grid>
        </Grid>

    return (
        <Container>
            <h4 >Create Credit Card</h4>
            <Box sx={{ width: "100%", mt: 3 }}>
                <form onSubmit={formik.handleSubmit} />
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <div>
                        <span className="h3" sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</span>

                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </div>
                ) : (
                    <div>
                        <Container sx={{ mt: 4 }}>
                            {activeStep === 0 && step1}
                            {activeStep === 1 && step2}
                        </Container>

                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            {cancelCallback && <Button color="error" onClick={cancelCallback} >Cancel</Button>}

                            <Box sx={{ flex: "1 1 auto" }} />

                            <Button className="mr-2"
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Anterior
                            </Button>

                            {activeStep === steps.length - 1 ?
                                <LoadingButton
                                    loading={saving}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    onClick={formik.handleSubmit}
                                >
                                    Guardar
                                </LoadingButton> :
                                <Button onClick={handleNext}>Siguiente</Button>}
                        </Box>
                    </div>
                )}
            </Box>
        </Container>

    );
}
