import * as React from 'react';
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas'
import { setStepPhotoSignature } from '../../_redux/CreatePassportReducer';

export default function StepPhotoSignature() {
    const [sigPad, setSigPad] = React.useState({});
    const dispatch = useDispatch()
    const stepReducer = useSelector(state => state.createPassport.stepPhotoSignature);
    const [errors, setErrors] = React.useState({
    });

    const createSignImg = async () => {
        dispatch(setStepPhotoSignature({
            ...stepReducer,
            values: {
                ...stepReducer.values,
                signature: sigPad.getTrimmedCanvas().toDataURL('image/png')
            }
        }))
    }

    const clearSignature = () => {
        sigPad.clear()
    }


    const changeInputPhoto = (input) => {
        setErrors({
            ...errors, passportPhoto: ""
        })
        // Assuming only image
        const file = input.currentTarget.files[0];
        const size = Math.round(file.size / 1024 / 1024);
        if (size > parseFloat(2)) {
            setErrors({
                ...errors, passportPhoto: "El tamaño maximo de la images es de 2 MB"
            })
            return false;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            console.log(reader.result);
            dispatch(setStepPhotoSignature({
                ...stepReducer,
                values: {
                    ...stepReducer.values,
                    passportPhoto: reader.result
                }
            }))
        };
        // TODO: concat files
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} xl={12}>
                <Button
                    size="small"
                    variant="contained"
                    component="label"
                >
                    Upload File
                    <input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={changeInputPhoto}
                        hidden
                    />
                </Button>

                {errors.passportPhoto && <FormHelperText className="text-danger">{errors.passportPhoto}</FormHelperText>}
                {stepReducer.values.passportPhoto && <div >
                    <img
                        className="photoPassport"
                        src={stepReducer.values.passportPhoto}
                        alt="Foto de pasaporte"
                        width={200}
                    />
                </div>}

            </Grid>
            <Grid item xs={12} sm={12} xl={12}>
                <div className="signatureContainer">
                    <h3 className="marginBottom">Firma</h3>
                    <Button sx={{ m: 1, ml: 0 }}
                        size="small"
                        variant="contained"
                        component="label"
                        onClick={clearSignature}
                        color="warning">
                        Borrar
                    </Button>
                </div>
                <SignatureCanvas penColor='black' backgroundColor='white' className='signature'
                    ref={(ref) => { setSigPad(ref) }}
                    onEnd={createSignImg}
                    maxWidth={2}
                    minWidth={2}
                    canvasProps={{ width: 'max', height: 100, className: 'sigCanvas' }} />
            </Grid>
        </Grid>
    )
}