import { BehaviorSubject } from 'rxjs';
import { authHeaderTypeJson, handleResponse } from '../_helpers';
import { URL_SERVER, AgencyId } from '../_settings/urlConfig';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    changePassword,
    changePhone,
    confirmChangePhone,
    resetPassword,
    confirmResetPassword,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

function login(phoneNumber, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-agency': AgencyId },
        body: JSON.stringify({
            password,
            phoneNumber,
        })
    };
    const url = `${URL_SERVER}/api/v1/Login`;
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user.object));
            currentUserSubject.next(user.object);

            return user;
        });
}

function changePassword(oldPassword, newPassword) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderTypeJson(),
        body: JSON.stringify({
            oldPassword,
            newPassword
        })
    };
    const url = `${URL_SERVER}/api/v1/Login/change_password`;
    return fetch(url, requestOptions)
        .then(handleResponse);
}

function changePhone(newPhoneNumber) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderTypeJson(),
        body: JSON.stringify({
            newPhoneNumber
        })
    };
    const url = `${URL_SERVER}/api/v1/Login/change_phone`;
    return fetch(url, requestOptions)
        .then(handleResponse);
}

function confirmChangePhone(smsCode) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderTypeJson(),
        body: JSON.stringify({
            smsCode
        })
    };
    const url = `${URL_SERVER}/api/v1/Login/confirm_change_phone`;
    return fetch(url, requestOptions)
        .then(handleResponse).then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user.object));
            currentUserSubject.next(user.object);

            return user;
        });
}

function resetPassword(phone) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-agency': AgencyId },
        body: JSON.stringify({
            phoneNumber: phone
        })
    };
    const url = `${URL_SERVER}/api/v1/Register/reset_password`;
    return fetch(url, requestOptions)
        .then(handleResponse);
}

function confirmResetPassword(smsCode, phoneNumber, newPassword) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-agency': AgencyId },
        body: JSON.stringify({
            smsCode,
            phoneNumber,
            newPassword
        })
    };
    const url = `${URL_SERVER}/api/v1/Register/confirm_reset_password`;
    return fetch(url, requestOptions)
        .then(handleResponse);
}


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}
