import * as React from 'react';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { setStepPersonalData } from '../../_redux/CreatePassportReducer';

export default function StepPersonalData() {
    const dispatch = useDispatch()
    const stepReducer = useSelector(state => state.createPassport.stepPersonalData);

    const formik = useFormik({
        initialValues: { ...stepReducer.values },
        initialTouched: { ...stepReducer.touched },
        validate: values => {
            const errors = {};
            if (!values.father) {
                errors.father = 'Este campo es obligatorio'
            }
            if (!values.mother) {
                errors.mother = 'Este campo es obligatorio'
            }
            if (!values.height) {
                errors.height = 'Este campo es obligatorio'
            }
            if (!values.gender) {
                errors.gender = 'Este campo es obligatorio'
            }
            if (!values.eyeColor) {
                errors.eyeColor = 'Este campo es obligatorio'
            }
            if (!values.skinColor) {
                errors.skinColor = 'Este campo es obligatorio'
            }
            if (!values.hairColor) {
                errors.hairColor = 'Este campo es obligatorio'
            }
            return errors;
        }
    })

    React.useEffect(() => {
        dispatch(setStepPersonalData({ ...stepReducer, values: formik.values, errors: formik.errors, touched: formik.touched }))
    }, [formik.values])

    React.useEffect(() => {
        dispatch(setStepPersonalData({ ...stepReducer, isValid: formik.isValid }))
    }, [formik.isValid])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} xl={4}>
                <TextField fullWidth label="Nombre padre" onBlur={formik.handleBlur} value={formik.values.father} name="father" onChange={formik.handleChange} helperText={formik.touched.father && formik.errors.father} error={formik.touched.father && formik.errors.father} variant="outlined" required />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <TextField fullWidth label="Nombre madre" onBlur={formik.handleBlur} value={formik.values.mother} name="mother" onChange={formik.handleChange} helperText={formik.touched.mother && formik.errors.mother} error={formik.touched.mother && formik.errors.mother} variant="outlined" required />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <NumberFormat
                    label="Estatura (cm)"
                    value={formik.values.height}
                    name="height"
                    customInput={TextField}
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.touched.height && formik.errors.height}
                    error={formik.touched.height && formik.errors.height}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <FormControl fullWidth error={formik.touched.gender && formik.errors.gender} >
                    <InputLabel id="gender">Sexo</InputLabel>
                    <Select
                        labelId="gender"
                        value={formik.values.gender}
                        name="gender"
                        label="Sexo"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        <MenuItem value="1">Femenino</MenuItem>
                        <MenuItem value="2">Masculino</MenuItem>
                    </Select>
                    {formik.touched.gender && formik.errors.gender && <FormHelperText>{formik.errors.gender}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <FormControl fullWidth error={formik.touched.eyeColor && formik.errors.eyeColor} >
                    <InputLabel id="eyeColor">Color de ojos</InputLabel>
                    <Select
                        labelId="eyeColor"
                        value={formik.values.eyeColor}
                        label="Color de ojos"
                        name="eyeColor"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        <MenuItem value="1">Claros</MenuItem>
                        <MenuItem value="2">Negros</MenuItem>
                        <MenuItem value="3">Pardos</MenuItem>
                    </Select>
                    {formik.touched.eyeColor && formik.errors.eyeColor && <FormHelperText>{formik.errors.eyeColor}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <FormControl fullWidth error={formik.touched.skinColor && formik.errors.skinColor} >
                    <InputLabel id="skinColor">Color de piel</InputLabel>
                    <Select
                        labelId="skinColor"
                        value={formik.values.skinColor}
                        label="Color de piel"
                        name="skinColor"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        <MenuItem value="1">Blanca</MenuItem>
                        <MenuItem value="2">Mulata</MenuItem>
                        <MenuItem value="3">Negra</MenuItem>
                        <MenuItem value="4">Albina</MenuItem>
                        <MenuItem value="5">Amarilla</MenuItem>
                    </Select>
                    {formik.touched.skinColor && formik.errors.skinColor && <FormHelperText>{formik.errors.skinColor}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <FormControl fullWidth error={formik.touched.hairColor && formik.errors.hairColor} >
                    <InputLabel id="hairColor">Color de cabello</InputLabel>
                    <Select
                        labelId="hairColor"
                        value={formik.values.hairColor}
                        label="Color de cabello"
                        name="hairColor"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        <MenuItem value="1">Canoso</MenuItem>
                        <MenuItem value="2">Rojo</MenuItem>
                        <MenuItem value="3">Negro</MenuItem>
                        <MenuItem value="4">Castaño</MenuItem>
                        <MenuItem value="5">Rubio</MenuItem>
                        <MenuItem value="6">Otros</MenuItem>
                    </Select>
                    {formik.touched.hairColor && formik.errors.hairColor && <FormHelperText>{formik.errors.hairColor}</FormHelperText>}
                </FormControl>
            </Grid>
        </Grid>
    )
}