import { URL_SERVER, AgencyId, URL_AGENCIA } from '../_settings/urlConfig';
import { authHeader, authHeaderTypeJson, handleResponse } from '../_helpers';

export const passportService = {
    getConfiguration,
    createPassport,
    getPassports,
    print,
    getPassport,
    updatePhoto,
    updateSignature
}

function getConfiguration() {
    const requestOptions = {
        method: 'GET',
        headers: { 'x-agency': AgencyId },
    };
    const url = `${URL_SERVER}/api/v1/Passport/GetConfigurationAgency/get_configuration`;
    return fetch(url, requestOptions).then(handleResponse);
}

function getPassports() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    const url = `${URL_SERVER}/api/v1/Passport/ListPassports`;
    return fetch(url, requestOptions).then(handleResponse);
}
function getPassport(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    const url = `${URL_SERVER}/api/v1/Passport/Get/${id}`;
    return fetch(url, requestOptions).then(handleResponse);
}

function createPassport(passport) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderTypeJson(),
        body: JSON.stringify(passport)
    };
    const url = `${URL_SERVER}/api/v1/Passport/PerformOrderPayment`;
    return fetch(url, requestOptions).then(handleResponse);
}

function print(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    const url = `${URL_AGENCIA}/Passport/createComprobante?id=${id}`;
    return fetch(url, requestOptions).then(response => {
        return response.text().then(text => {
            return text;
        });
    });
}

function updatePhoto(id, image) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderTypeJson(),
        body: JSON.stringify({
            passportId: id,
            image
        })
    };
    const url = `${URL_SERVER}/api/v1/Passport/UpdatePhoto`;
    return fetch(url, requestOptions).then(handleResponse);
}

function updateSignature(id, image) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderTypeJson(),
        body: JSON.stringify({
            passportId: id,
            image
        })
    };
    const url = `${URL_SERVER}/api/v1/Passport/UpdateSignature`;
    return fetch(url, requestOptions).then(handleResponse);
}
