import { LoadingButton } from "@mui/lab";
import { Button, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, TextField } from "@mui/material"
import { Box } from "@mui/system";
import { City, State } from "country-state-city";
import { useFormik } from 'formik';
import React, { useState } from "react";
import * as Yup from 'yup';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getOnlyNumber } from "../../Tools/StringTools";
import { accountService } from "../../_services";

const EditProfile = () => {
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const states = State.getStatesOfCountry("US");
    const [cities, setCities] = useState([]);

    const EditSchema = Yup.object().shape({
        firstName: Yup.string().required('Este campo es obligatorio'),
        firstLastName: Yup.string().required('Este campo es obligatorio'),
        secondLastName: Yup.string().required('Este campo es obligatorio'),
        email: Yup.string().email("El email no es correcto").required('Este campo es obligatorio'),
        address: Yup.string().required('Este campo es obligatorio'),
        zip: Yup.string().required('Este campo es obligatorio'),
        state: Yup.string().required('Este campo es obligatorio'),
        city: Yup.string().required('Este campo es obligatorio'),
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            secondName: '',
            firstLastName: '',
            secondLastName: '',
            email: '',
            address: '',
            zip: '',
            country: 'United States',
            countryIso2: 'US',
            state: '',
            city: '',
        },
        validationSchema: EditSchema,

        onSubmit: () => save(),
    })
    const { values } = formik;

    React.useEffect(() => {
        loadData();
    }, [])

    const loadCities = (stateCode) => {
        const data = City.getCitiesOfState("US", stateCode);
        setCities(data);
    }

    const save = async () => {
        const model = {
            firstName: values.firstName,
            secondName: values.secondName,
            firstLastName: values.firstLastName,
            secondLastName: values.secondLastName,
            email: values.email,
            address: {
                // "id": "string",
                // "referenceId": "string",
                // "current": true,
                // "type": "string",
                addressLine1: values.address,
                addressLine2: "",
                city: values.city,
                state: values.state,
                zip: values.zip,
                country: values.country,
                countryiso2: values.countryIso2
            }
        }
        const isUpdated = await accountService.updateAccount(model).then(res => {
            if (res.success) {
                addToast("Los datos han sido actualizados", {
                    autoDismiss: true,
                    appearance: "success"
                })
                return true;
            }
            addToast(res.errorMessage, {
                autoDismiss: true,
                appearance: "info"
            })
            return false;
        }, error => {
            console.log(error);
            addToast('No se han podido actualizar los datos', {
                autoDismiss: true,
                appearance: "error"
            })
            return false;
        })

        if (isUpdated) {
            navigate("/dashboard/profile");
            return true;
        }

        return false;
    }

    function loadData() {
        accountService.getAccount().then(res => {
            if (res.success) {
                formik.setFieldValue("firstName", res.object.firstName);
                formik.setFieldValue("secondName", res.object.secondName);
                formik.setFieldValue("firstLastName", res.object.firstLastName);
                formik.setFieldValue("secondLastName", res.object.secondLastName);
                formik.setFieldValue("email", res.object.email);
                formik.setFieldValue("address", res.object.address.addressLine1);
                formik.setFieldValue("state", res.object.address.state);
                formik.setFieldValue("city", res.object.address.city);
                formik.setFieldValue("zip", res.object.address.zip);
            }
        })
    }

    return (
        <Paper elevation={3} sx={{ p: 2, ml: 2, mr: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} xl={12}>
                    <h3>Editar Perfil</h3>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} xl={4}>
                                <TextField fullWidth label="Primer Nombre" onBlur={formik.handleBlur} value={formik.values.firstName} name="firstName" onChange={formik.handleChange} helperText={formik.touched.firstName && formik.errors.firstName} error={formik.touched.firstName && formik.errors.firstName} variant="outlined" required />
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4}>
                                <TextField fullWidth label="Segundo Nombre" onBlur={formik.handleBlur} value={formik.values.secondName} name="secondName" onChange={formik.handleChange} helperText={formik.touched.secondName && formik.errors.secondName} error={formik.touched.secondName && formik.errors.secondName} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4}>
                                <TextField fullWidth label="Primer Apellido" onBlur={formik.handleBlur} value={formik.values.firstLastName} name="firstLastName" onChange={formik.handleChange} helperText={formik.touched.firstLastName && formik.errors.firstLastName} error={formik.touched.firstLastName && formik.errors.firstLastName} variant="outlined" required />
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4}>
                                <TextField fullWidth label="Segundo Apellido" onBlur={formik.handleBlur} value={formik.values.secondLastName} name="secondLastName" onChange={formik.handleChange} helperText={formik.touched.secondLastName && formik.errors.secondLastName} error={formik.touched.secondLastName && formik.errors.secondLastName} variant="outlined" required />
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4}>
                                <TextField fullWidth label="Dirección" onBlur={formik.handleBlur} value={formik.values.address} name="address" onChange={formik.handleChange} helperText={formik.touched.address && formik.errors.address} error={formik.touched.address && formik.errors.address} variant="outlined" required />
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4}>
                                <FormControl fullWidth error={formik.touched.state && formik.errors.state} >
                                    <InputLabel id="state">Estado</InputLabel>
                                    <Select
                                        labelId="state"
                                        value={formik.values.state}
                                        name="state"
                                        label="Estado"
                                        onBlur={formik.handleBlur}
                                        onChange={(e) => { formik.handleChange(e); loadCities(e.target.value) }}
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {states.map((value) => {
                                            return <MenuItem value={value.isoCode}>{value.name}</MenuItem>
                                        })}
                                    </Select>
                                    {formik.touched.state && formik.errors.state && <FormHelperText>{formik.errors.state}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4}>
                                <FormControl fullWidth error={formik.touched.city && formik.errors.city} >
                                    <InputLabel id="city">Ciudad</InputLabel>
                                    <Select
                                        labelId="city"
                                        value={formik.values.city}
                                        name="city"
                                        label="Ciudad"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {cities.map((value) => {
                                            return <MenuItem selected={value.name === formik.values.city} value={value.name}>{value.name}</MenuItem>
                                        })}
                                    </Select>
                                    {formik.touched.city && formik.errors.city && <FormHelperText>{formik.errors.city}</FormHelperText>}
                                </FormControl>            </Grid>
                            <Grid item xs={12} sm={6} xl={4}>
                                <TextField fullWidth label="Código Postal" onBlur={formik.handleBlur} value={formik.values.zip} name="zip" onChange={formik.handleChange} helperText={formik.touched.zip && formik.errors.zip} error={formik.touched.zip && formik.errors.zip} variant="outlined" required />
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4}>
                                <TextField fullWidth label="Email" onBlur={formik.handleBlur} value={formik.values.email} name="email" onChange={formik.handleChange} helperText={formik.touched.email && formik.errors.email} error={formik.touched.email && formik.errors.email} variant="outlined" required />
                            </Grid>
                        </Grid>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Button sx={{ mr: 1 }}
                                color="secondary"
                                onClick={() => navigate(-1)}
                                variant="contained">
                                Regresar
                            </Button>
                            <LoadingButton
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                type="submit"
                                variant="contained"
                                loading={formik.isSubmitting}
                            >
                                Guardar
                            </LoadingButton>
                        </Box>
                    </form>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default EditProfile;