import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import creditCardFill from '@iconify/icons-eva/credit-card-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import phoneFill from '@iconify/icons-eva/smartphone-outline';
import keyFill from '@iconify/icons-eva/keypad-outline';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';

//
import { authenticationService, accountService, passportService } from '../../_services';
import { setProfile, setCurrentUser } from '../../_redux/AccountReducer';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Perfil',
    icon: personFill,
    linkTo: '/dashboard/profile'
  },
  {
    label: 'Cambiar Contraseña',
    icon: keyFill,
    linkTo: '/dashboard/profile/change-password'
  },
  {
    label: 'Cambiar Teléfono',
    icon: phoneFill,
    linkTo: '/dashboard/profile/change-phone'
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch = useDispatch();
  const account = useSelector((s) => s.account.profile);
  const user = useSelector((s) => s.account.currentUser);
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [configuration, setConfiguration] = useState(null);

  useEffect(async () => {
    updateProfile();
    setConfiguration(await getConfiguration());
  }, []);

  const getConfiguration = async () => {
    const config = await passportService.getConfiguration();
    return config.object;
  };

  const updateProfile = async () => {
    const currentUser = authenticationService.currentUserValue;
    dispatch(setCurrentUser(currentUser));
    if (currentUser) {
      const profile = await accountService
        .getAccount()
        .then((res) => {
          if (res.success) return res.object;
          return null;
        })
        .catch((error) => {
          console.log(error);
          return null;
        });
      dispatch(setProfile(profile));
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const login = () => {
    navigate('/login');
  };

  const logout = () => {
    authenticationService.logout();
    navigate('/login');
  };

  const openWhatsapp = () => {};

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={openWhatsapp}
        sx={{
          padding: 0,
          width: 40,
          height: 40,
          backgroundColor: 'green',
          marginRight: 2
        }}
      >
        <a
          href={`https://api.whatsapp.com/send?phone=${configuration?.phoneSupport?.phone}&text=${configuration?.phoneSupport?.textDefault}`}
          target="_blank"
          rel="noreferrer"
        >
          {' '}
          <WhatsAppIcon sx={{ color: 'white', marginTop: 1 }} />
        </a>
      </IconButton>

      <IconButton
        ref={anchorRef}
        onClick={user != null ? handleOpen : login}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src="/static/mock-images/avatars/avatar_default.jpg" alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {account?.firstName} {account?.firstLastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={logout}>
            Cerrar sesión
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
