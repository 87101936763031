import { LoadingButton } from "@mui/lab";
import { Button, Divider, FormHelperText, Grid, Paper, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useParams } from "react-router";
import SaveIcon from '@mui/icons-material/Save';
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas'

import { isNullOrEmpty } from "../../Tools/StringTools";
import { passportService } from "../../_services";
import { URL_AGENCIA } from "../../_settings/urlConfig";

const ChangeSignaturePage = () => {
    const { id } = useParams();
    const { addToast } = useToasts();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [passport, setPassport] = useState(null);
    const [signature, setSignature] = useState(null);
    const [sigPad, setSigPad] = React.useState({});

    React.useEffect(async () => {
        const data = await getPassport();
        setPassport(data);
    }, [])

    const getPassport = () => {
        return passportService.getPassport(id).then(res => {
            if (res.success)
                return res.object;

            return null;
        }, error => {
            console.log(error);
            return null;
        })
    }

    const createSignImg = async () => {
        setSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'));
    }

    const clearSignature = () => {
        sigPad.clear()
    }

    const save = async () => {
        setLoading(true);
        if (isNullOrEmpty(signature)) {
            addToast("Debe firmar", {
                autoDismiss: true,
                appearance: "info",
            });
            setLoading(false);
            return false;
        }

        // submit
        const image = signature.split("base64,")[1];
        const response = await passportService.updateSignature(id, image).then(res => {
            if (res.success)
                return true;

            addToast(res.errorMessage, {
                autoDismiss: true,
                appearance: "info",
            })
            return false;
        }, error => {
            console.log(error);
            addToast("Ha ocurridoun error", {
                autoDismiss: true,
                appearance: "error",
            })
        })

        if (response) {
            const data = await getPassport();
            setPassport(data);
            clearSignature();
            addToast("La firma ha sido actualizada", {
                autoDismiss: true,
                appearance: "success",
            })
        }

        setLoading(false);
    }

    return (
        <Paper elevation={3} sx={{ p: 2, ml: 2, mr: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} xl={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item><h3>Firma de Pasaporte</h3></Grid>

                    </Grid>
                    <Divider />
                </Grid>
                {passport &&
                    <Grid item xs={12} sm={12} xl={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} xl={4} >
                                <h4>Firma actual:</h4>
                                {isNullOrEmpty(passport.signature) ?
                                    <span>No existe firma</span>
                                    :
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 75,
                                            width: 150,
                                            maxHeight: { xs: 233, md: 167 },
                                            maxWidth: { xs: 350, md: 250 },
                                        }}
                                        alt="Firma de pasaporte."
                                        src={`${URL_AGENCIA}${passport.signature}`}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} xl={4}>
                                <div className="signatureContainer">
                                    <h4 className="marginBottom">Nueva Firma</h4>
                                    <Button sx={{ ml: 1 }}
                                        size="small"
                                        variant="contained"
                                        component="label"
                                        onClick={clearSignature}
                                        color="warning">
                                        Borrar
                                    </Button>
                                </div>
                                <SignatureCanvas penColor='black' backgroundColor='white' className='signature'
                                    ref={(ref) => { setSigPad(ref) }}
                                    onEnd={createSignImg}
                                    maxWidth={2}
                                    minWidth={2}
                                    canvasProps={{ width: 'max', height: 100, className: 'sigCanvas' }} />
                            </Grid>
                        </Grid>
                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                            <Button
                                color="secondary"
                                onClick={() => navigate(-1)}
                                variant="contained">
                                Regresar
                            </Button>
                            <LoadingButton
                                color="primary"
                                onClick={save}
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                            >
                                Actualizar
                            </LoadingButton>
                        </Stack>
                    </Grid>
                }
            </Grid>
        </Paper>
    )
}

export default ChangeSignaturePage;