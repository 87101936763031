import { URL_SERVER, AgencyId, URL_AGENCIA } from '../_settings/urlConfig';
import { authHeader, authHeaderTypeJson, handleResponse } from '../_helpers';

function create(model) {
  const requestOptions = {
    method: 'POST',
    headers: authHeaderTypeJson(),
    body: JSON.stringify({
      data: JSON.stringify(model),
      servicioConsular: model.consularService
    })
  };
  const url = `${URL_SERVER}/api/v1/IncompletePassport`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeaderTypeJson()
  };
  const url = `${URL_SERVER}/api/v1/IncompletePassport`;
  return fetch(url, requestOptions).then(handleResponse);
}

function get(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeaderTypeJson()
  };
  const url = `${URL_SERVER}/api/v1/IncompletePassport/${id}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function update(id, model) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeaderTypeJson(),
    body: JSON.stringify({
      data: JSON.stringify(model),
      servicioConsular: model.consularService
    })
  };
  const url = `${URL_SERVER}/api/v1/IncompletePassport/${id}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function remove(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeaderTypeJson()
  };
  const url = `${URL_SERVER}/api/v1/IncompletePassport/${id}`;
  return fetch(url, requestOptions).then(handleResponse);
}

export const incompletePassportService = {
  create,
  getAll,
  get,
  update,
  remove
};
