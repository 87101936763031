export const getOnlyNumber = (value) => {
    if (!value)
        return "";
    const numberPattern = /\d+/g;
    const matchs = value.match(numberPattern)
    let numbers = "";
    matchs.map((elem) => numbers += elem)

    return numbers;
}

export const isNullOrEmpty = (value) => {
    if (!value || value === "")
        return true;

    return false;
}