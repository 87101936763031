import { createSlice } from '@reduxjs/toolkit';

export const accountReducer = createSlice({
    name: 'account',
    initialState: {
        currentUser: null,
        profile: null
    },
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        setProfile: (state, action) => {
            state.profile = action.payload;
        },
    },
});

export const { setCurrentUser, setProfile } = accountReducer.actions;

export default accountReducer.reducer;
