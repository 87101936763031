import { createSlice } from '@reduxjs/toolkit';

export const layoutReducer = createSlice({
    name: 'layout',
    initialState: {
        blockUI: false
    },
    reducers: {
        setBlockUI: (state, action) => {
            state.blockUI = action.payload;
        },
    },
});

export const { setSidebarShow, setBlockUI } = layoutReducer.actions;

export default layoutReducer.reducer;
