import '../../_styles/PassportList.css';
import { Container, Grid, List, Paper } from '@mui/material';
import React from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { setValues, setRefIdSaved } from '../../_redux/CreatePassportReducer';

import { servicioConsular } from '../../_helpers/enums';
import { incompletePassportService } from '../../_services';

const ListPassportPendingPage = () => {
  const [passports, setPassports] = React.useState([]);
  const { addToast } = useToasts();

  React.useEffect(async () => {
    getPassports();
  }, []);

  const getPassports = async () => {
    const data = await incompletePassportService.getAll();
    console.log(data);
    if (data?.success) {
      setPassports(data.object);
    } else {
      addToast('No se han podido obtener los datos', {
        appearance: 'warning',
        autoDismiss: true
      });
    }
  };
  return (
    <Container>
      <h3>Órdenes Incompletas</h3>
      <List style={{ maxHeight: '100%', overflow: 'auto' }}>
        {passports.map((item, i) => {
          return <ListItem key={i} item={item} />;
        })}
      </List>
    </Container>
  );
};

const ListItem = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const getServicioConsular = (servicio) => {
    switch (servicio) {
      case servicioConsular.PrimerVez:
        return 'Primera Vez';
      case servicioConsular.PrimerVez2:
        return 'Primera Vez Menor';
      case servicioConsular.Renovacion2:
        return 'renovacion Menor';
      case servicioConsular.Renovacion:
        return 'Renovación';
      default:
        return '';
    }
  };

  const build = async () => {
    const data = await incompletePassportService.get(item.id);
    if (data?.success) {
      const dataValues = JSON.parse(data.object.data);
      dispatch(
        setValues({
          ...dataValues
        })
      );
      dispatch(setRefIdSaved(data.object.id));

      if (item.servicioConsular === servicioConsular.PrimerVez) navigate(`/dashboard/primervez`);
      if (item.servicioConsular === servicioConsular.Renovacion) navigate(`/dashboard/renovar`);
      if (item.servicioConsular === servicioConsular.PrimerVez2)
        navigate(`/dashboard/primervezmenor`);
      if (item.servicioConsular === servicioConsular.Renovacion2)
        navigate(`/dashboard/renovarmenor`);
    } else {
      addToast('No se han podido obtener los datos', {
        appearance: 'warning',
        autoDismiss: true
      });
    }
  };

  return (
    <Paper elevation={3} sx={{ m: 1, mb: 2, p: 1, cursor: 'pointer' }} onClick={build}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <p>
            <b>Fecha:</b> {moment(new Date(item.createAt)).format('MM/DD/YYYY hh:mm:ss')}
          </p>
          <p>
            <b>Tipo de Servicio:</b> {getServicioConsular(item.servicioConsular)}
          </p>
        </Grid>
        <Grid item>
          <NumberFormat displayType="text" decimalSeparator="." value={item.amount} prefix="$" />
          <p>{item.status}</p>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ListPassportPendingPage;
