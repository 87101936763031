import * as React from 'react';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { setStepLaboralData } from '../../_redux/CreatePassportReducer';

const professions = [
    "Medico",
    "Profesor",
    "Escritor",
    "Estudiante",
    "Obrero",
    "Tecnico",
    "Empresario",
    "Artista",
    "Religioso",
    "Informatico Tecnico",
    "Abogado",
    "Funcionario",
    "Politico",
    "Informatico Superior",
    "Licenciado",
    "Cientifico",
    "Intelectual",
    "Diplomatico",
    "Campesino",
    "Enfermera",
    "Empleado",
    "Deportista",
    "Comerciante",
    "Periodista",
    "Ama de Casa",
    "Ingeniero",
    "Otro",
]

const nivelEscolar = [
    "Analfabeto",
    "Primario",
    "Secundario",
    "Pre-Universitario",
    "Tecnico medio",
    "Universitario",
]

export default function StepLaboralData() {
    const dispatch = useDispatch()
    const stepReducer = useSelector(state => state.createPassport.stepLaboralData);
    const { otherProfession } = stepReducer;

    const formik = useFormik({
        initialValues: { ...stepReducer.values },
        initialTouched: { ...stepReducer.touched },
        validate: values => {
            const errors = {};
            if (!values.jobCenterName) {
                errors.jobCenterName = 'Este campo es obligatorio'
            }
            if (!values.profession) {
                errors.profession = 'Este campo es obligatorio'
            }
            if (!values.occupation) {
                errors.occupation = 'Este campo es obligatorio'
            }
            if (!values.categoryProfession) {
                errors.categoryProfession = 'Este campo es obligatorio'
            }
            if (!values.schoolLevel) {
                errors.schoolLevel = 'Este campo es obligatorio'
            }
            return errors;
        }
    })

    const professionChange = e => {
        const { value } = e.target;
        if (value === "Otro") {
            dispatch(setStepLaboralData({ ...stepReducer, otherProfession: true }))
        }
    }

    React.useEffect(() => {
        dispatch(setStepLaboralData({ ...stepReducer, values: formik.values, errors: formik.errors, touched: formik.touched }))
    }, [formik.values])

    React.useEffect(() => {
        dispatch(setStepLaboralData({ ...stepReducer, isValid: formik.isValid }))
    }, [formik.isValid])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} xl={4}>
                <TextField fullWidth label="Nombre del centro de trabajo" onBlur={formik.handleBlur} value={formik.values.jobCenterName} name="jobCenterName" onChange={formik.handleChange} helperText={formik.touched.jobCenterName && formik.errors.jobCenterName} error={formik.touched.jobCenterName && formik.errors.jobCenterName} variant="outlined" required />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                {otherProfession ?
                    <TextField fullWidth label="Profesion" onBlur={formik.handleBlur} value={formik.values.profession} name="profession" onChange={formik.handleChange} helperText={formik.touched.profession && formik.errors.profession} error={formik.touched.profession && formik.errors.profession} variant="outlined" required />
                    :
                    <FormControl fullWidth error={formik.touched.profession && formik.errors.profession} >
                        <InputLabel id="profession">Profesion</InputLabel>
                        <Select
                            labelId="profession"
                            value={formik.values.profession}
                            name="profession"
                            label="Profesion"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                                formik.handleChange(e);
                                professionChange(e);
                            }}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {professions.map((item) => {
                                return <MenuItem value={item}>{item}</MenuItem>
                            })}
                        </Select>
                        {formik.touched.profession && formik.errors.profession && <FormHelperText>{formik.errors.profession}</FormHelperText>}
                    </FormControl>
                }

            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <TextField fullWidth label="Ocupación" onBlur={formik.handleBlur} value={formik.values.occupation} name="occupation" onChange={formik.handleChange} helperText={formik.touched.occupation && formik.errors.occupation} error={formik.touched.occupation && formik.errors.occupation} variant="outlined" required />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <FormControl fullWidth error={formik.touched.categoryProfession && formik.errors.categoryProfession} >
                    <InputLabel id="categoryProfession">Categoría de profesión</InputLabel>
                    <Select
                        labelId="categoryProfession"
                        value={formik.values.categoryProfession}
                        name="categoryProfession"
                        label="Categoría de profesión"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    >
                        <MenuItem value="0"><em>None</em></MenuItem>
                        <MenuItem value="1">Cuenta Propista</MenuItem>
                        <MenuItem value="2">Deporte</MenuItem>
                        <MenuItem value="3">Educacion</MenuItem>
                        <MenuItem value="4">Salud</MenuItem>
                        <MenuItem value="5">Turismo</MenuItem>
                        <MenuItem value="6">Otros</MenuItem>

                    </Select>
                    {formik.touched.categoryProfession && formik.errors.categoryProfession && <FormHelperText>{formik.errors.categoryProfession}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <FormControl fullWidth error={formik.touched.schoolLevel && formik.errors.schoolLevel} >
                    <InputLabel id="schoolLevel">Nivel escolar</InputLabel>
                    <Select
                        labelId="schoolLevel"
                        value={formik.values.schoolLevel}
                        name="schoolLevel"
                        label="Nivel Escolar"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {
                            nivelEscolar.map((item) => {
                                return <MenuItem value={item}>{item}</MenuItem>
                            })
                        }
                    </Select>
                    {formik.touched.schoolLevel && formik.errors.schoolLevel && <FormHelperText>{formik.errors.schoolLevel}</FormHelperText>}
                </FormControl>
            </Grid>

        </Grid>
    )
}